import colors from "../../assets/colors.module.scss";
const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: colors.spacing_4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  appBar: {
    backgroundColor: "inherit",
    height: "48px",
    justifyContent: "flex-end",
    position: "fixed",
  },
});

export default styles;
