import React, { useState, useContext } from "react";
import SelectCustomerDrawer from "../InvoicesCreate/StepOne/SelectCustomerDrawer";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { TerminalContext } from "./TerminalContext";
import Tooltip from "@mui/material/Tooltip";
import { PAYMENT_TYPES } from "./terminalConstants";
const CustomerSelect = () => {
  const [showCustomer, setSearchCustomer] = useState(false);
  const { userSettings } = useContext(UserSettingsContext);
  const { customer, setCustomer, paymentType } = useContext(TerminalContext);
  const handleCustomerChange = (customerId) => {
    if (!customerId) {
      setCustomer(null);
      return;
    }

    const url = stringFormat(sassEndpoints.customers.customerId, [
      userSettings.gatewayId,
      customerId,
    ]);

    axios.get(url).then((response) => {
      if (response.status === 200) {
        setCustomer(response.data.data);
      }
    });

    setSearchCustomer(false);
  };

  return (
    <div className="customer-section" style={{ marginBottom: "16px" }}>
      {customer ? (
        <div className="customer-name">
          <p>{customer.name}</p>
          <IconButton
            sx={{ paddingBottom: "0px" }}
            className="delete-btn"
            onClick={() => {
              setCustomer(null);
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      ) : (
        <>
          <Tooltip
            title={
              paymentType === PAYMENT_TYPES.VERIFICATION
                ? "Adding a customer is not supported for the selected transaction type."
                : ""
            }
          >
            <button
              onClick={() => {
                setSearchCustomer(true);
              }}
              disabled={paymentType === PAYMENT_TYPES.VERIFICATION}
              className="btn btn--secondary"
            >
              <div>
                <PersonAddAltIcon /> Add A Customer
              </div>
            </button>
          </Tooltip>
        </>
      )}
      <SelectCustomerDrawer
        open={showCustomer}
        setOpenDrawer={setSearchCustomer}
        onCustomerSelect={handleCustomerChange}
      />
    </div>
  );
};

export default CustomerSelect;
