import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const FilterDrawer = ({
  filters,
  values,
  open,
  setOpenDrawer,
  onReset,
  onFilter,
  isDirty,
  handleFilterChange,
}) => {
  const theme = useTheme();

  const getFilterComponent = (filter, index) => {
    switch (filter.type) {
      case "text":
        return (
          <Grid item xs={12} key={index}>
            <TextField
              fullWidth
              className="search-list-filter-field"
              name={`filter.${filter.name}`}
              label={filter.label}
              value={values[filter.name]}
              onChange={(e) => {
                handleFilterChange(filter.name, e.target.value);
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </Grid>
        );

      case "select":
        return (
          <Grid item xs={12} key={index}>
            <TextField
              select
              fullWidth
              name={`filter.${filter.name}`}
              label={filter.label}
              value={values[filter.name]}
              onChange={(e) => {
                handleFilterChange(filter.name, e.target.value);
              }}
              InputLabelProps={{
                shrink: false,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {filter.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        );

      case "date":
        return (
          <Grid item xs={12} key={index}>
            <TextField
              type="date"
              label={filter.label}
              name={`filter.${filter.name}`}
              value={values[filter.name]}
              onChange={(e) => {
                handleFilterChange(
                  filter.name,
                  e.target.value !== ""
                    ? new Date(e.target.value).toISOString().split("T")[0]
                    : "",
                );
              }}
              inputProps={{
                sx: {
                  colorScheme: theme.palette.mode,
                },
              }}
              InputLabelProps={{
                shrink: false,
              }}
              fullWidth
            />
          </Grid>
        );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      className={theme.palette.mode}
      onClose={() => setOpenDrawer(false)}
      PaperProps={{
        className: "filter-drawer v2",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" className="filter-heading">
            Filter By
          </Typography>
        </Grid>
        <Grid item container className="filter-close-button-container">
          <IconButton
            aria-label="close"
            className="filter-close-button"
            onClick={() => setOpenDrawer(false)}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container spacing={3} className="filter-inputs">
        {filters.map((filter, index) => getFilterComponent(filter, index))}
      </Grid>
      <Divider variant="middle" />
      <Grid container justifyContent="flex-end">
        <Button
          variant="text"
          id="reset-button"
          onClick={onReset}
          disabled={!isDirty}
        >
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={onFilter}>
          Show Results
        </Button>
      </Grid>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "date", "checkbox", "select"]).isRequired,
      toolbar: PropTypes.bool.isRequired,
      operator: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  ),
  values: PropTypes.object,
  open: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default FilterDrawer;
