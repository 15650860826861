import { useEffect, useState } from "react";
import Select from "../ReactHookForm/Select";
import { MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";
import RegisteredTextField from "../ReactHookForm/RegisteredTextField";
import PropTypes from "prop-types";
import axios from "axios";
import { regexPatterns } from "../../constants/regexPatterns";
import { validationRules } from "../../constants/validationRules";
import "./achTokenizer.scss";
import { basysEndpoints } from "../../constants/endpoints";

const ACHTokenizerComponent = ({
  handleAchChange,
  achSubmit,
  setAchSubmit,
  achToken,
  setAchToken,
  showAlertDialogError,
  clearForm,
  setIsAlertOpen,
  availableSEC,
}) => {
  const methods = useFormContext();
  const [hasCheckNumber, setHasCheckNumber] = useState(false);

  const defaultSecCodes = [
    { label: "WEB", value: "web" },
    { label: "PPD", value: "ppd" },
    { label: "CCD", value: "ccd" },
    { label: "TEL", value: "tel" },
  ];
  const propSecCodes = availableSEC?.map((c) => {
    return { label: c, value: c.toLowerCase() };
  });
  const availableSecCodes = propSecCodes ?? defaultSecCodes;

  useEffect(() => {
    if (achSubmit && achToken === null) {
      let url = basysEndpoints.ach_tokenizer;
      let payload = {
        accountNumber: methods.getValues().ach_account_number,
        routingNumber: methods.getValues().ach_routing_number,
        secCode: methods.getValues().ach_sec_code,
        achAccountType: methods.getValues().ach_account_type,
        checkNumber: methods.getValues().check_number,
      };
      axios
        .post(url, payload)
        .then((response) => {
          setAchToken(response.data.data.achId);
        })
        .catch(() => {
          if (showAlertDialogError) {
            showAlertDialogError(
              "Something went wrong tokenizing payment method",
            );
          } else if (setIsAlertOpen) {
            setIsAlertOpen(true);
          }
          setAchToken(null);
          setAchSubmit(false);
        });
    }
  }, [achSubmit, achToken]);

  useEffect(() => {
    methods.setValue("ach_account_number", "");
    methods.setValue("ach_routing_number", "");
  }, [clearForm]);

  useEffect(() => {
    return () => {
      methods.unregister("ach_account_number");
      methods.unregister("ach_routing_number");
      methods.unregister("check_number");
      methods.unregister("ach_account_type");
      methods.unregister("ach_sec_code");
    };
  }, []);

  return (
    <>
      <div className="ach-form">
        <div className="account">
          <div
            style={{
              display: "block",
              fontSize: "14px",
              padding: "0 0 7px 1px",
            }}
          >
            Account Number *
          </div>
          <RegisteredTextField
            className="ach-input"
            id="achAccountNumber"
            name="ach_account_number"
            sx={{ width: "100%" }}
            rules={{
              required: {
                value: true,
                message: "Account Number is required and must be numeric",
              },
              pattern: {
                value: regexPatterns.numeric,
                message: "Account Number is required and must be numeric",
              },
              maxLength: validationRules.maxLength19,
            }}
          ></RegisteredTextField>
        </div>
        <div className="routing">
          <div
            style={{
              display: "block",
              fontSize: "14px",
              padding: "0 0 7px 1px",
            }}
          >
            Routing Number *
          </div>
          <RegisteredTextField
            className="ach-input"
            id="achRoutingNumber"
            name="ach_routing_number"
            sx={{ width: "100%" }}
            rules={{
              required: {
                value: true,
                message: "Routing Number is required and must be 9 digits",
              },
              pattern: {
                value: regexPatterns.numeric,
                message: "Routing Number is required and must be 9 digits",
              },
              maxLength: {
                value: 9,
                message: "Routing Number is required and must be 9 digits",
              },
              minLength: {
                value: 9,
                message: "Routing Number is required and must be 9 digits",
              },
            }}
          ></RegisteredTextField>
        </div>
        {hasCheckNumber && (
          <div className="check">
            <div
              style={{
                display: "block",
                fontSize: "14px",
                padding: "0 0 7px 1px",
              }}
            >
              Check Number *
            </div>
            <RegisteredTextField
              className="check-number"
              id="checkNumber"
              name="check_number"
              fullWidth
              rules={{
                required: hasCheckNumber && "required",
                pattern: {
                  value: regexPatterns.numeric,
                  message: "Only numbers allowed",
                },
              }}
            ></RegisteredTextField>
          </div>
        )}

        <div className="type">
          <div style={{ fontSize: "14px", padding: "0 0 7px 1px" }}>
            Account Type *
          </div>
          <Select
            control={methods.control}
            id="achAccountType"
            name="ach_account_type"
            className="ach-select"
            defaultValue={"Checking"}
            sx={{ display: "block" }}
          >
            <MenuItem key={`0`} value={"Checking"}>
              Checking
            </MenuItem>
            <MenuItem key={`1`} value={"Savings"}>
              Savings
            </MenuItem>
          </Select>
        </div>
        <div className="sec">
          <div style={{ fontSize: "14px", padding: "0 0 7px 1px" }}>
            SEC Code *
          </div>
          <Select
            control={methods.control}
            id="achSecCode"
            name="ach_sec_code"
            className="ach-select"
            defaultValue={"ppd"}
            sx={{ display: "block" }}
            onChange={(e) => {
              setHasCheckNumber(e.toLowerCase() === "tel");
              handleAchChange && handleAchChange(e);
            }}
          >
            {availableSecCodes.map((option, index) => (
              <MenuItem key={`${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};
ACHTokenizerComponent.propTypes = {
  handleAchChange: PropTypes.func,
  achSubmit: PropTypes.bool,
  achToken: PropTypes.any,
  setAchToken: PropTypes.func,
  setAchSubmit: PropTypes.func,
  showAlertDialogError: PropTypes.func,
  clearForm: PropTypes.bool,
  setIsAlertOpen: PropTypes.func,
  availableSEC: PropTypes.array,
};

export default ACHTokenizerComponent;
