import React from "react";
import PropTypes from "prop-types";
import { TransactionStatusDescriptions } from "./physicalTerminalConstants";
import error from "./assets/error-pt.png";
const TerminalError = ({ returnToTransaction, code }) => {
  return (
    <div className="payment-success-modal pt-success-wrapper">
      <div>
        <img src={error} />
        <h2>{TransactionStatusDescriptions[code]?.description}</h2>
        {/* <h2>Transaction Declined</h2> */}
        <p>Error Code: {code}</p>
        {/* <p>Error Code: 112</p> */}
        <button onClick={returnToTransaction} className="btn btn--primary">
          Rerun Transaction
        </button>
      </div>
    </div>
  );
};
TerminalError.propTypes = {
  returnToTransaction: PropTypes.func,
  code: PropTypes.number,
};

export default TerminalError;
