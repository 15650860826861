import React, { useContext, useEffect, useState, useRef } from "react";
import SearchList from "../../../Search/SearchList/SearchList";
import { sassFilterOperators } from "../../../Search/searchHelpers";
import { stringFormat } from "../../../../utils/stringHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import _ from "lodash";
import axios from "axios";
import ContentComponent from "../../../Content/Content";
import { Button, Grid, Typography } from "@mui/material";
import "../../../../assets/v2/main.scss";
import "./subscriptions.scss";
import { SUBSCRIPTION_STATUSES } from "../../../../constants/global";
import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionDetailsContainer from "./SubscriptionDetails/SubscriptionDetailsContainer";
import { statusTag } from "./subscriptionHelpers";
import { useAxios } from "../../../../hooks";
import emptyState from "../../../../assets/images/empty-state.png";
import { useHistory, useLocation } from "react-router-dom";

const SubscriptionsContainer = () => {
  const { userSettings } = useContext(UserSettingsContext);

  const [resultsPerPage] = useState(10);
  const [resultOffset, setResultOffset] = useState(0);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [subscriptionResults, setSubscriptionResults] = useState(null);
  const [globalFilters, setGlobalFilters] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const [formIsDirty, setFormIsDirty] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const resizeRef = useRef();
  const { data: billingPeriods, loading: periodsLoading } = useAxios(
    {
      method: "GET",
      url: sassEndpoints.subscriptions.billingPeriods,
    },
    { initialData: [], dataTransform: (data) => data.data },
  );

  useEffect(() => {
    if (sessionStorage && sessionStorage.getItem("newSubscription")) {
      toggleCreate();
    }
  }, []);

  useEffect(() => {
    if (subscriptionResults?.length && activeIndex !== null) loadSubscription();
  }, [subscriptionResults, activeIndex]);

  const showAlertDialogError = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const showConfirmDialog = (index) => {
    setAlertDialogProps({
      alertTitle: "Are you sure?",
      alertLevel: "info",
      alertMessages: [
        "If you continue any information you entered will be lost",
      ],
      actionButtons: [
        {
          text: "Yes, Continue",
          color: "primary",
          onclick: () => changeCard(index),
        },
      ],
      closeButtonText: "Cancel",
      closeButtoncolor: "primary",
      closeButtonVariant: "outlined",
      onCloseButtonClick: () => setAlertDialogOpen(false),
    });
    setAlertDialogOpen(true);
  };

  const changeCard = (index) => {
    setAlertDialogOpen(false);
    setFormIsDirty(false);
    setActiveIndex(index);
    setMode("view");
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  const reload = () => {
    window.scrollTo(0, 0);
    loadSubscriptions(globalFilters, sortField, sortDirection, true);
  };

  const loadSubscriptions = (filters, column, direction, reload = false) => {
    let url = stringFormat(sassEndpoints.subscriptions.search, [
      userSettings.gatewayId,
    ]);
    let payload = filters ? structuredClone(filters) : {};
    payload.sortColumn = column;
    payload.sortDirection = direction;
    payload.limit = resultsPerPage;
    payload.offset = resultOffset;

    if (location.state?.subscriptionId) {
      payload.subscriptionId = {
        operator: "Equal",
        value: location.state.subscriptionId,
      };
      history.replace({ state: {} });
    }

    if (filters !== null && !_.isEqual(globalFilters, filters)) {
      setGlobalFilters(filters);
      setSubscriptionResults([]);
      setMode("view");
      setActiveIndex(0);
      setResultsTotal(0);
      setResultOffset(0);
      payload.offset = 0;
    } else if (filters === null && Object.keys(globalFilters).length > 0) {
      setGlobalFilters({});
      setSubscriptionResults([]);
      setMode("view");
      setActiveIndex(0);
      setResultsTotal(0);
      setResultOffset(0);
      payload.offset = 0;
    }

    if (direction !== sortDirection) {
      setSortDirection(direction);
      setSubscriptionResults([]);
      setMode("view");
      setActiveIndex(0);
      setResultsTotal(0);
      setResultOffset(0);
      payload.offset = 0;
    }

    if (column !== sortField) {
      setSortField(column);
      setSubscriptionResults([]);
      setMode("view");
      setActiveIndex(0);
      setResultsTotal(0);
      setResultOffset(0);
      payload.offset = 0;
    }

    if (reload) {
      setMode("view");
      setSubscriptionResults([]);
      setActiveIndex(0);
      setResultOffset(0);
      setResultsTotal(0);
      payload.offset = 0;
    }

    return axios
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 204) {
          setSubscriptionResults([]);
          setResultsTotal(0);
        } else {
          setSubscriptionResults((prevSubs) => {
            return [...(prevSubs || []), ...resp.data.data.results];
          });
          setResultOffset((prevOffset) => {
            return prevOffset + resultsPerPage;
          });
          setResultsTotal(resp.data.data.rowCount);
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to retrieve subscriptions.",
        );
      });
  };

  const loadSubscription = () => {
    setLoading(true);

    let url = stringFormat(sassEndpoints.subscriptions.subscriptionId, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .get(url)
      .then((resp) => {
        setActiveSubscription(resp.data.data);
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to retrieve subscription.",
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createSubscription = (payload) => {
    setSubmitting(true);

    let url = stringFormat(sassEndpoints.subscriptions.subscription, [
      userSettings.gatewayId,
    ]);

    axios
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 201) {
          showSnackBar("Subscription created");
          reload();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to create subscription.",
        );
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const updateSubscription = (payload) => {
    setSubmitting(true);

    let url = stringFormat(sassEndpoints.subscriptions.subscriptionId, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .put(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          showSnackBar("Subscription updated");
          reload();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to update subscription.",
        );
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const pauseSubscription = () => {
    setActionLoading(true);

    let url = stringFormat(sassEndpoints.subscriptions.pause, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .put(url)
      .then((resp) => {
        if (resp.status === 200) {
          showSnackBar("Subscription paused");
          reload();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to pause subscription.",
        );
        setActionLoading(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const resumeSubscription = (payload) => {
    setActionLoading(true);

    let url = stringFormat(sassEndpoints.subscriptions.resume, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .put(url, payload)
      .then((resp) => {
        if (resp.status === 200) {
          showSnackBar("Subscription resumed");
          reload();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to resume subscription.",
        );
        setActionLoading(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const cancelSubscription = (payload) => {
    setActionLoading(true);

    let url = stringFormat(sassEndpoints.subscriptions.cancel, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .post(url, payload)
      .then((resp) => {
        if (resp.status === 204) {
          showSnackBar(
            payload.cancel.now
              ? "Successfully cancelled Subscription"
              : "Successfully scheduled cancellation",
          );
          reload();
        } else {
          showAlertDialogError(
            // eslint-disable-next-line prettier/prettier
            resp?.data?.data?.statusDetails?.join(", ") ||
              "Successfully cancelled subscription, but there were other errors",
          );
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to cancel subscription.",
        );
        setActionLoading(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const deleteSubscription = () => {
    setActionLoading(true);

    let url = stringFormat(sassEndpoints.subscriptions.subscriptionId, [
      userSettings.gatewayId,
      subscriptionResults[activeIndex].subscriptionId,
    ]);

    axios
      .delete(url)
      .then((resp) => {
        if (resp.status === 204) {
          showSnackBar("Subscription deleted");
          reload();
        }
      })
      .catch((error) => {
        showAlertDialogError(
          // eslint-disable-next-line prettier/prettier
          error.response?.data?.statusDetails?.join(", ") ||
            "Failed to delete subscription.",
        );
        setActionLoading(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const toggleCreate = () => {
    setActiveIndex(null);
    setActiveSubscription(null);
    setMode("create");
  };

  return (
    <ContentComponent
      useFixedWidth={false}
      title="Recurring Billing"
      spinnerOpen={actionLoading}
      bodyContent={
        <div className="v2">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="create-div"
            marginBottom={2}
          >
            <Grid item xs={10.5}>
              Charge customers on a recurring basis through Subscriptions.
            </Grid>
            <Grid item container xs={1.5} justifyContent="flex-end">
              <Button
                className="btn--primary"
                onClick={toggleCreate}
                data-cy="create-subscription"
              >
                Create New +
              </Button>
            </Grid>
          </Grid>
          <SearchList
            title="Subscriptions"
            data={subscriptionResults}
            fetchData={loadSubscriptions}
            hasMoreData={subscriptionResults?.length !== resultsTotal}
            cardProps={{
              getContent: (sub) =>
                `$${sub.remit.totalAmount.toFixed(2)} / ${
                  sub.recurrence.billingPeriod?.name || "Custom Period"
                }`,
              getTopLeft: (sub) => (
                <Typography variant="h3">{sub.name}</Typography>
              ),
              getTopRight: (sub) => statusTag(sub.status.name),
              getBottomLeft: (sub) => (
                <div>
                  <p>{sub.customer.name}</p>
                  <p>{sub.customer.email}</p>
                </div>
              ),
              onClick: (index) => {
                if (formIsDirty) showConfirmDialog(index);
                else changeCard(index);
              },
            }}
            filters={[
              {
                label: "Global Search",
                name: "globalSearch",
                type: "text",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription ID",
                name: "subscriptionId",
                type: "text",
                toolbar: true,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription Name",
                name: "subscriptionName",
                type: "text",
                toolbar: true,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Customer Name",
                name: "customerName",
                type: "text",
                toolbar: true,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Status",
                name: "statusName",
                type: "select",
                options: [
                  {
                    label: SUBSCRIPTION_STATUSES.DRAFT,
                    value: SUBSCRIPTION_STATUSES.DRAFT,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.SCHEDULED,
                    value: SUBSCRIPTION_STATUSES.SCHEDULED,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.TRIALING,
                    value: SUBSCRIPTION_STATUSES.TRIALING,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.ACTIVE,
                    value: SUBSCRIPTION_STATUSES.ACTIVE,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.OVERDUE,
                    value: SUBSCRIPTION_STATUSES.OVERDUE,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.PAUSED,
                    value: SUBSCRIPTION_STATUSES.PAUSED,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.CANCELLED,
                    value: SUBSCRIPTION_STATUSES.CANCELLED,
                  },
                  {
                    label: SUBSCRIPTION_STATUSES.COMPLETE,
                    value: SUBSCRIPTION_STATUSES.COMPLETE,
                  },
                ],
                toolbar: true,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription Number",
                name: "subscriptionNumber",
                type: "text",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription Note",
                name: "subscriptionNote",
                type: "text",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription Start Date",
                name: "termStartDate",
                type: "date",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Subscription End Date",
                name: "termEndDate",
                type: "date",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Billing Period",
                name: "billingPeriodName",
                type: "select",
                toolbar: false,
                operator: sassFilterOperators.is.value,
                options: billingPeriods?.map((bp) => ({
                  label: bp.name,
                  value: bp.name,
                })),
              },
              {
                label: "Billing Start Date",
                name: "billingStartDate",
                type: "date",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Invoice Prefix",
                name: "subscriptionPrefix",
                type: "text",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Created Date",
                name: "createdDate",
                type: "date",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
              {
                label: "Modified Date",
                name: "modifiedDate",
                type: "date",
                toolbar: false,
                operator: sassFilterOperators.is.value,
              },
            ]}
            sortColumns={[
              {
                label: "Subscription Name",
                value: "Name",
              },
              {
                label: "Subscription Number",
                value: "Number",
              },
              {
                label: "Invoice Prefix",
                value: "Prefix",
              },
              {
                label: "Billing Period",
                value: "BillingPeriodName",
              },
              {
                label: "Start Date",
                value: "TermStartDate",
              },
              {
                label: "End Date",
                value: "TermEndDate",
              },
              {
                label: "Billing Start Date",
                value: "BillingStartDate",
              },
              {
                label: "Created Date Time",
                value: "CreatedDateTime",
                isDefault: true,
              },
            ]}
            activeIndex={activeIndex}
            detailsRef={resizeRef}
            details={
              (subscriptionResults?.length || mode === "create") &&
              !periodsLoading ? (
                <SubscriptionDetailsContainer
                  ref={resizeRef}
                  mode={mode}
                  setMode={setMode}
                  activeSubscription={activeSubscription}
                  setSubscription={setActiveSubscription}
                  setFormIsDirty={setFormIsDirty}
                  billingPeriods={billingPeriods}
                  showErrorMessage={showAlertDialogError}
                  submitting={submitting}
                  onSave={createSubscription}
                  onUpdate={updateSubscription}
                  onPause={pauseSubscription}
                  onResume={resumeSubscription}
                  onCancel={cancelSubscription}
                  onDelete={deleteSubscription}
                />
              ) : loading || periodsLoading ? (
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <p>Loading</p>
                  <CircularProgress
                    className="loading-icon"
                    color="inherit"
                    size={15}
                  />
                </Grid>
              ) : (
                <Grid item height="100%" alignContent="center">
                  <Grid container textAlign="center" flexDirection="column">
                    <Grid>
                      <img src={emptyState}></img>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <strong>
                          {Object.keys(globalFilters).length > 0
                            ? "No Subscriptions Found. There aren't any results with the applied filter(s)."
                            : "No Subscriptions Found"}
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          />
        </div>
      }
      alertDialogOpen={alertDialogOpen}
      alertDialogProps={alertDialogProps}
      snackbarOpen={snackbarOpen}
      snackbarProps={snackbarProps}
    />
  );
};

export default SubscriptionsContainer;
