export const TransactionStatusDescriptions = {
  "-1": {
    description: "Catch all for anything that's not currently handled",
    endProcess: true,
    errorState: true,
  },
  "-100": {
    description: "Transaction errored out",
    endProcess: true,
    errorState: true,
  },
  100: {
    description: "Terminal accepted transaction",
    endProcess: false,
    errorState: false,
  },
  200: {
    description: "Terminal rejected transaction",
    endProcess: true,
    errorState: true,
  },
  300: {
    description: "Waiting on the customer to present card",
    endProcess: false,
    errorState: false,
  },
  400: {
    description: "Customer canceled transaction",
    endProcess: true,
    errorState: false,
  },
  500: {
    description: "Customer presented card",
    endProcess: false,
    errorState: false,
  },
  800: {
    description:
      "Waiting on customer to approve amount (this is based on surcharge)",
    endProcess: false,
    errorState: false,
  },
  900: {
    description: "Customer approved the amount",
    endProcess: false,
    errorState: false,
  },
  1000: {
    description: "Transaction approved",
    endProcess: true,
    errorState: false,
  },
  1100: {
    description: "Transaction declined",
    endProcess: true,
    errorState: true,
  },
  1200: {
    description: "Merchant canceled transaction",
    endProcess: true,
    errorState: true,
  },
};

export const StepperDescription = {
  0: {
    description: "Establishing Connection",
  },
  1: {
    description: "Terminal accepted transaction request",
  },
  2: {
    description: "Waiting on customer to present card",
  },
  3: {
    description: "Customer presented card",
  },
  4: {
    description: "Transaction complete",
  },
};

export const StepperDescriptionSurcharge = {
  0: {
    description: "Establishing Connection",
  },
  1: {
    description: "Terminal accepted transaction request",
  },
  2: {
    description: "Waiting on customer to present card", //Go to this after receiving 300 status
  },
  3: {
    description: "Customer presented card", //Go to this after receiving 500 status
  },
  4: {
    description: "Waiting on customer to approve amount", //Go to this after receiving 800, BUT can be skipped if the card isn't surchargable
  },
  5: {
    description: "Customer approved transaction", //Go to this after receiving 900, BUT can be skipped if the card isn't surchargable
  },
  6: {
    description: "Transaction complete", //Go to this after receiving 1000 status
  },
};

export const ModalStatus = {
  VIEW: 0,
  WARN: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export const PaymentTypes = {
  SALE: 0,
  AUTHORIZE: 1,
  CREDIT: 3,
};
