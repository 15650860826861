import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import "./gatewayModalSelector.scss";
import TOSText from "../../Help/HelpTOS";
import DialogActions from "@mui/material/DialogActions";
import { useMsal } from "@azure/msal-react";
import Fab from "@mui/material/Fab";
import { logout } from "../../../utils/logout";
import SaveIcon from "@mui/icons-material/Save";
import logo from "../../Drawer/iqpro-logo.png";
import darkLogo from "../../Drawer/iqPro-dark-mode.png";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { basysEndpoints } from "../../../constants/endpoints";
import { useTheme } from "@mui/material/styles";

const AcceptTosModal = ({ onOpen, onClose }) => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading(true);
    axios
      .put(`${basysEndpoints.tos}/accept`)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setError(error?.response?.data);
        setLoading(false);
      });
  };

  const logoutUser = () => {
    logout(instance);
  };

  return (
    <Dialog
      className="large-modal-container"
      onClose={handleClose}
      open={onOpen}
    >
      <div className="tos-modal-wrapper">
        <div className={theme.palette.mode + "-theme"}>
          <img
            className="logo-header"
            src={theme.palette.mode === "light" ? logo : darkLogo}
            alt="Logo"
          />
          <DialogTitle>Review and Accept Terms of Service</DialogTitle>
          <div
            className="text-section"
            aria-label="You may stay on the terms of service or proceed to the accept button."
          >
            <TOSText />
          </div>
          {error && <p>{error}</p>}

          <DialogActions>
            <div className="loading-indicator-buttons">
              <Box className="fab-btn" sx={{ m: 1, position: "relative" }}>
                <Fab
                  aria-label="save"
                  color="primary"
                  disabled={loading}
                  onClick={() => handleClose()}
                >
                  <SaveIcon />
                </Fab>
                {loading && (
                  <CircularProgress
                    size={68}
                    sx={{
                      position: "absolute",
                      top: -6,
                      left: -6,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
              <Box className="loading-btn" sx={{ m: 1, position: "relative" }}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Accept
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </div>

            <Button
              fullWidth
              size="small"
              variant="contained"
              color="neutrals"
              onClick={() => {
                logoutUser();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

AcceptTosModal.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.bool,
  gateway: PropTypes.object,
};

export default AcceptTosModal;
