import { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import VirtualTerminal from "./VirtualTerminal";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import { CONTACT_SUPPORT, iqProVer } from "../../../constants/global";
import { useAxios } from "../../../hooks/useAxios";
import { useAlertDialog } from "../../../hooks/useAlertDialog";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { sassEndpoints } from "../../../constants/endpoints";
import { formatAsContactAddress } from "./Customer";
import * as mp from "../../../services/mixpanel/merchant/tokenizerEvents";
import * as transactionMp from "../../../services/mixpanel/merchant/reportingTransactionsEvents";
import { stringFormat, capitalize } from "../../../utils/stringHelpers";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { noProcessors } from "../../../constants/routes";
import AlertDialog from "../../Dialog/AlertDialog";
import { CountriesContext } from "../../../contexts/CountriesContext";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";

const validationAlertMessage = [
  "Please correct validation errors highlighted in red.",
];

const getDefaultCustomerAddresses = (customerInfo) => {
  const billing = customerInfo?.addresses?.find((address) => {
    return address.isBilling;
  });
  const shipping = customerInfo?.addresses?.find((address) => {
    return address.isShipping;
  });

  let addresses = {};

  if (billing) addresses.billing = formatAsContactAddress(billing);
  if (shipping) addresses.shipping = formatAsContactAddress(shipping);

  return addresses;
};

const VirtualTerminalContainer = ({ history }) => {
  const { ...methods } = useForm();
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { id: customerToken } = useParams();
  const [customerInfo, setCustomerInfo] = useState(null);
  const [isCustomerInfoLoading, setIsCustomerInfoLoading] = useState(
    customerToken ? true : false,
  );
  const { isAlertOpen, setIsAlertOpen, alertMessages, displayMessages } =
    useAlertDialog(validationAlertMessage);
  const { userSettings } = useContext(UserSettingsContext);
  const [tokenizer, setTokenizer] = useState(null);
  const tokenizerApiKey = userSettings.publicKey;
  const [paymentOption, setPaymentOption] = useState("card");
  const [selectedPaymentTab, setSelectedPaymentTab] = useState(
    customerToken ? "Customer" : "Card",
  );
  const [defaultAddresses, setDefaultAddresses] = useState(null);
  const [requiredContactFields, setRequiredContactFields] = useState([]);

  const [tokenRes, setTokenRes] = useState(null);
  const [achToken, setAchToken] = useState(null);
  const [achSubmit, setAchSubmit] = useState(false);
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const countries = useContext(CountriesContext);
  const [customFieldsResponse, setCustomFieldsResponse] = useState(null);
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState(null);
  const [customFieldsError, setCustomFieldsError] = useState(false);
  const tokenizerV2 = useRef();
  const [processors, setProcessors] = useState(null);
  const [isProcessorsLoading, setIsProcessorsLoading] = useState(false);
  const [processorsError, setIsProcessorsError] = useState(false);
  const [partialProcessed, setPartiallyProcessed] = useState(false);

  const [payload, setPayload] = useState({
    type: "sale",
    payment_method: {
      token: "",
    },
  });

  const {
    data: gatewayConfig,
    loading: isGatewayConfigLoading,
    error: gatewayConfigError,
  } = useAxios(
    {
      method: "GET",
      url: stringFormat(sassEndpoints.gateways.configuration, [
        userSettings.gatewayId,
      ]),
    },
    {
      dataTransform: (data) => data.data,
    },
  );

  const {
    data: gatewayInfo,
    loading: isGatewayInfoLoading,
    error: gatewayInfoError,
  } = useAxios(
    {
      method: "GET",
      url: stringFormat(sassEndpoints.gateways.gateway, [
        userSettings.gatewayId,
      ]),
    },
    {
      dataTransform: (data) => data.data,
    },
  );

  useEffect(() => {
    setIsProcessorsLoading(true);
    let url = stringFormat(sassEndpoints.processors.processor, [
      userSettings.gatewayId,
    ]);
    axios
      .get(url)
      .then((resp) => {
        if (resp.status === 204) {
          history.push(noProcessors);
        } else if (resp.data.data.length > 0) {
          setProcessors(resp.data.data);
        }
      })
      .catch(() => {
        setIsProcessorsError(true);
      })
      .finally(() => {
        setIsProcessorsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (achToken) {
      setAchSubmit(false);
      handleTokenizerSubmission({ status: "success", token: achToken });
    }
  }, [achToken]);

  useEffect(async () => {
    if (!customFieldsResponse) {
      let url = stringFormat(sassEndpoints.customFields.category, [
        userSettings.gatewayId,
      ]);
      setIsCustomFieldsLoading(true);
      axios
        .get(url)
        .then(function (res) {
          if (res.status === 204) {
            setCustomFieldsResponse([]);
            setCustomFieldGroups([]);
            setChosenCustomFields([]);
          } else {
            let transactionGroups = res.data.data.filter(
              (group) => group.transaction === true,
            );
            let groupNames = transactionGroups?.map((group) => group.name);
            let customFields = transactionGroups?.map(
              (fields) => fields.customFields,
            );
            setCustomFieldsResponse(res.data.data);
            setCustomFieldGroups(groupNames);
            setChosenCustomFields(customFields);
          }
        })
        .catch(function () {
          setCustomFieldsError(true);
        })
        .finally(function () {
          setIsCustomFieldsLoading(false);
        });
    }
  }, [customFieldsResponse]);

  useEffect(() => {
    if (
      !isGatewayConfigLoading &&
      !isGatewayInfoLoading &&
      !isProcessorsLoading &&
      !isCustomFieldsLoading &&
      !isCustomerInfoLoading
    ) {
      setIsInitialPageLoad(false);
      if (!tokenizerApiKey) {
        displayMessages(["Tokenizer failed to load", CONTACT_SUPPORT]);
      } else if (
        customFieldsError ||
        processorsError ||
        gatewayConfigError ||
        gatewayInfoError
      ) {
        displayMessages(["Request failed", CONTACT_SUPPORT]);
      }
    }
  }, [
    isGatewayConfigLoading,
    isGatewayInfoLoading,
    isProcessorsLoading,
    isCustomFieldsLoading,
    isCustomerInfoLoading,
  ]);

  const handleTokenizerSubmission = (resp) => {
    if (resp.status === "success") {
      submitTransaction(resp.token);
    } else {
      displayMessages([resp.msg]);
      mp.tokenizerValidationErrorEvent(resp);
    }
  };

  const handleSaasTokenizerSubmission = (data, exp) => {
    Reflect.set(data, "expiration", exp);
    setTokenRes(data);
  };

  useEffect(() => {
    if (customerToken) {
      let url = stringFormat(sassEndpoints.customers.customerId, [
        userSettings.gatewayId,
        customerToken,
      ]);
      setIsCustomerInfoLoading(true);
      axios
        .get(url)
        .then((response) => {
          const customerInfo = response?.data.data;
          if (customerInfo) {
            setDefaultAddresses(getDefaultCustomerAddresses(customerInfo));
          }
          setCustomerInfo(customerInfo);
        })
        .catch(function (error) {
          setCustomerInfo(null);
          displayMessages(
            error?.response?.data?.statusDetails ||
              "Failed to retrieve customer.",
          );
        })
        .finally(function () {
          setIsCustomerInfoLoading(false);
        });
    } else {
      setCustomerInfo(null);
    }
  }, [customerToken]);

  const submitTransaction = async (token) => {
    setTokenRes(token);
  };

  //
  // We're using the token response as a dependency to trigger a useEffect as initial useStates
  // were being passed into transaction functions.
  useEffect(() => {
    if (tokenRes) {
      handleTransaction(tokenRes);
    }
  }, [tokenRes]);

  const handleCustomField = () => {
    let customFieldArray = [];
    let chosenGroup = methods.getValues().customer_field_group_select;

    methods.getValues().custom_fields[chosenGroup].forEach((fieldValue) => {
      if (fieldValue !== null && fieldValue.length > 0) {
        customFieldArray.push({
          customFieldId: methods
            .getValues()
            .custom_fields[chosenGroup].indexOf(fieldValue),
          customFieldValue:
            //field value here is required to be type [string]
            typeof fieldValue === "object" ? fieldValue : [fieldValue],
        });
      }
    });
    return customFieldArray;
  };

  const handleOnSubmit = (data) => {
    if (data.customer_field_group_select) {
      data.customFields = handleCustomField();
    }
    delete data.total;
    delete data.sec_code;
    delete data.allow_partial_payment;
    delete data.bypass_rule_engine;
    data.create_vault_record = data.create_vault_record === "true";

    data.email_receipt = data.email_receipt === "true";

    if (
      gatewayInfo.gatewaySettings
        .find((s) => s.code === "ALLOW_SURCHARGE")
        ?.value.toLowerCase() === "true" ||
      data?.payment_adjustment?.type === "none"
    ) {
      delete data.payment_adjustment;
    }
    const formattedPayload = transformDataToSaaS(data);
    setPayload(Object.assign(payload, formattedPayload));

    if (tokenizer) {
      delete payload?.payment_method?.customer;
      tokenizer.submit();
    } else {
      if (
        tokenizerSettings.achTokenizer === iqProVer.v2 ||
        tokenizerSettings.cardTokenizer === iqProVer.v2
      ) {
        selectedPaymentTab === "Card"
          ? tokenizerV2?.current?.validate()
          : selectedPaymentTab === "ACH"
            ? setAchSubmit(true)
            : handleTransaction();
      } else {
        handleTransaction();
      }
    }
  };

  const transformDataToSaaS = (data) => {
    const addresses = formatAddresses(data);
    let returnPayload = {
      type: capitalize(data?.type),
      orderId: data?.order_id,
      poNumber: data?.po_number,
      description: data?.description,
      source: "API",
      vaultCustomer: data?.create_vault_record,
      customerName:
        data.create_vault_record === true
          ? data?.billing_address?.first_name +
            " " +
            data?.billing_address?.last_name
          : "",
      emailReceipt: data?.email_receipt,
      emailAddress: data?.email_address,
      remit: {
        baseAmount: (data?.amount / 100).toFixed(2),
        currencyCode: "USD",
        isTaxExempt: data?.tax_exempt,
        addTaxToTotal: Object.keys(data).includes("add_tax_to_total")
          ? data?.add_tax_to_total
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "default_values",
              "Virtual Terminal",
              "add_tax_to_total",
            ).toLowerCase() === "true",
        paymentAdjustments: [],
      },
      processorId: data?.processor_id,
      ...(data?.payment_method?.customer && {
        paymentMethod: {
          customer: {
            customerId: data?.payment_method?.customer?.id,
            customerPaymentMethodId:
              data?.payment_method?.customer?.payment_method_id,
            // customerBillingAddressId:
            //   data?.payment_method?.customer?.billing_address_id,
            // customerShippingAddressId:
            //   data?.payment_method?.customer?.shipping_address_id,
          },
        },
      }),
      address: addresses,
    };

    if ((data?.tax_amount || 0) > 0) {
      returnPayload.remit.paymentAdjustments.push({
        type: "Tax",
        flatAmount: data?.tax_amount
          ? (data?.tax_amount / 100).toFixed(2)
          : "0.00",
      });
    }

    if (data.payment_adjustment) {
      if (data.payment_adjustment.type === "Percentage") {
        returnPayload.remit.paymentAdjustments.push({
          type: "ServiceFee",
          percentage: (data.payment_adjustment.value / 1000).toFixed(3),
        });
      }
      if (data.payment_adjustment.type === "Flat") {
        returnPayload.remit.paymentAdjustments.push({
          type: "ConvenienceFee",
          flatAmount: (data.payment_adjustment.value / 100).toFixed(2),
        });
      }
    }

    if (data.customFields) {
      returnPayload.customFields = data.customFields;
    }

    return returnPayload;
  };

  const formatAddresses = (data) => {
    // return array of addresses
    let addresses = [];
    let types = ["billing", "shipping"];
    types.forEach((type) => {
      let filledAddress = false;
      Object.entries(data[`${type}_address`]).forEach((val) => {
        if (val[0] !== "country" && val[1]) {
          filledAddress = true;
        }
      });
      if (filledAddress) {
        const details = data[`${type}_address`];
        const address = {
          isPhysical: false,
          isShipping: type === "shipping",
          isBilling: type === "billing",
          firstName: details?.first_name || "",
          lastName: details?.last_name || "",
          company: details?.company || "",
          email: details?.email || "",
          phone: details?.phone || "",
          fax: details?.fax || "",
          addressLine1: details?.address_line_1 || "",
          addressLine2: details?.address_line_2 || "",
          city: details?.city || "",
          state: details?.state || "",
          postalCode: details?.postal_code || "",
          country: details?.country || "",
        };
        addresses.push(address);
      }
    });
    return addresses;
  };

  const formatPaymentMethod = (token) => {
    if (selectedPaymentTab === "Card" || selectedPaymentTab === "Swipe") {
      return tokenizerSettings.cardTokenizer === iqProVer.v2
        ? {
            card: {
              cardToken: token.token,
              expirationDate: token.expiration,
              maskedNumber: token.firstSix + "******" + token.lastFour,
            },
          }
        : {
            card: {
              cardToken: token,
              maskedNumber:
                methods.getValues().bin.substring(0, 6) + "******1111",
            },
          };
    } else {
      return {
        ach: {
          achToken: token,
        },
      };
    }
  };

  const getMixPanelProps = () => {
    return {
      transactionDetails: payload,
      selectedPaymentTab: selectedPaymentTab,
      secCode: methods.getValues().sec_code,
      customFields: customFieldsResponse || [],
    };
  };

  const handleOnError = () => displayMessages(validationAlertMessage);

  const handleTransaction = async (token) => {
    let callEmailReceipt = false;
    delete payload.payment_method;
    if (token) payload.paymentMethod = formatPaymentMethod(token);
    if (payload.emailReceipt) {
      payload.emailReceipt = false;
      callEmailReceipt = true;
    }
    setShowBackdrop(true);
    let url = stringFormat(sassEndpoints.transactions.transaction, [
      userSettings.gatewayId,
    ]);
    await axios
      .post(url, payload)
      .then(function (response) {
        const mixPanelProps = getMixPanelProps();
        mp.transactionEvent(mixPanelProps);
        if (response.status === 207) {
          setPartiallyProcessed(response.data.statusDetails[0]);
        }
        let transaction_id = response?.data?.data?.transactionId;
        if (callEmailReceipt) {
          emailTransaction(transaction_id, payload.emailAddress);
        } else if (transaction_id) {
          history.push(
            `${merchantRoutes.reporting.transaction_detail}/${transaction_id}`,
          );
        }
      })
      .catch(function (error) {
        setAchToken(null);
        displayMessages(error?.response?.data?.statusDetails);
        mp.failedTransactionEvent(
          methods.getValues(),
          "Failed",
          selectedPaymentTab,
        );
      })
      .finally(function () {
        setShowBackdrop(false);
        setTokenRes(null);
      });
  };

  const emailTransaction = async (transactionId, emailAddresses) => {
    let mixpanelPayload = {
      page: "virtual terminal transaction",
    };
    const url = stringFormat(sassEndpoints.transactions.emailReceipt, [
      userSettings.gatewayId,
      transactionId,
    ]);
    let emailAdressesArray = emailAddresses.split(",");
    for (let index in emailAdressesArray) {
      Object.assign(mixpanelPayload, {
        transaction_id: transactionId,
        action_type: "emailed",
        email: emailAdressesArray[index].trim(),
      });
      await axios
        .post(url, {
          recipient: {
            to: [emailAdressesArray[index].trim()],
            name: "",
          },
          from: gatewayInfo.receiptEmail || "",
        })
        .then(() => {
          history.push(
            `${merchantRoutes.reporting.transaction_detail}/${transactionId}`,
          );
          transactionMp.reportingTransactionsActionSuccess(mixpanelPayload);
        })
        .catch(function () {
          history.push({
            pathname: `${merchantRoutes.reporting.transaction_detail}/${transactionId}`,
            state: {
              emailCallFailed: true,
            },
          });
          transactionMp.reportingTransactionsActionFailed(mixpanelPayload);
        });
    }
  };

  return (
    <>
      <AlertDialog
        alertOpen={partialProcessed !== false}
        alertMessages={[partialProcessed]}
        closeButtonText="Ok"
        closeButtonColor="secondary"
        onCloseButtonClick={() =>
          history.push(merchantRoutes.reporting.transactions)
        }
        partiallyProcessed={partialProcessed}
      />
      <VirtualTerminal
        isInitialPageLoad={isInitialPageLoad}
        showBackDrop={showBackdrop}
        isCustomerInfoLoading={isCustomerInfoLoading}
        onSubmit={handleOnSubmit}
        onError={handleOnError}
        customerInfo={customerInfo}
        paymentOption={paymentOption}
        setPaymentOption={setPaymentOption}
        useLineItems={false}
        methods={methods}
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        alertMessages={alertMessages}
        tokenizer={tokenizer}
        setTokenizer={setTokenizer}
        tokenizerApiKey={tokenizerApiKey}
        handleTokenizerSubmission={handleTokenizerSubmission}
        gatewayConfig={gatewayConfig}
        gatewayInfo={gatewayInfo}
        processors={processors}
        requiredContactFields={requiredContactFields}
        setRequiredContactFields={setRequiredContactFields}
        customFieldGroups={customFieldGroups}
        chosenCustomFields={chosenCustomFields}
        countries={countries}
        defaultAddresses={defaultAddresses}
        setDefaultAddresses={setDefaultAddresses}
        selectedPaymentTab={selectedPaymentTab}
        setSelectedPaymentTab={setSelectedPaymentTab}
        achToken={achToken}
        setAchToken={setAchToken}
        achSubmit={achSubmit}
        setAchSubmit={setAchSubmit}
        tokenizerV2={tokenizerV2}
        handleSaasTokenizerSubmission={handleSaasTokenizerSubmission}
      />
    </>
  );
};

export default VirtualTerminalContainer;

VirtualTerminalContainer.propTypes = {
  history: PropTypes.object.isRequired,
};
