export const PAYMENT_TYPES = {
  SALE: "sale",
  AUTHORIZATION: "authorize",
  VERIFICATION: "verification",
  CREDIT: "credit",
};

export const ADJUSTMENT_TYPES = {
  FLAT: "flat",
  PERCENTAGE: "percentage",
  NONE: "noadj",
};

export const defaultRequiredFields = {
  firstName: true,
  lastName: true,
  company: false,
  address1: true,
  address2: false,
  country: true,
  state: true,
  city: true,
  postalCode: true,
  email: false,
  phone: false,
  fax: false,
};
