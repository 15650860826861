import React, { useContext, useState } from "react";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import { useWatch } from "react-hook-form";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { TerminalContext } from "./TerminalContext";
import Dialog from "@mui/material/Dialog";
import PhysicalTerminalContainer from "./PhysicalTerminal/PhysicalTerminalContainer";
import CardPaymentContainer from "./CardPayment/CardPaymentContainer";
import ACHPaymentContainer from "./ACHPayment/ACHPaymentContainer";
import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../../../utils/msalHelpers";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { PAYMENT_TYPES } from "./terminalConstants";
const PaymentMethodSelector = ({ resetState }) => {
  const gatewayInfo = useContext(GatewayContext);
  const { paymentType, customer } = useContext(TerminalContext);
  const amount = useWatch({ name: "amount", defaultValue: 0 });
  const [open, setOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [jwtToken, setToken] = useState(null);
  const [paymentModal, setPaymentModal] = useState(null);
  const [isFullWidth, setFullWidth] = useState(false);
  const theme = useTheme();

  const surchargeEnabled =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value?.toLowerCase() === "true";

  // Hidden for 10/1
  const showACHPayment = () => {
    return (
      gatewayInfo.processors.some((p) => {
        return p.processorType.type === "Ach";
      }) &&
      localStorage.getItem("VT2") &&
      !surchargeEnabled
    );
  };
  const showPhysical = () => {
    return gatewayInfo.processors.some((p) => {
      return p.processorType.certificationCategory === "PhysicalTerminal";
    });
  };

  const showVirtual = () => {
    return gatewayInfo.processors.some((p) => {
      return (
        p.processorType.certificationCategory === "VirtualTerminal" &&
        p.processorType.type === "CreditCard" &&
        localStorage.getItem("VT2") &&
        !surchargeEnabled
      );
    });
  };

  const handlePaymentSelection = (type) => {
    setFullWidth(false);
    if (type === "PT") {
      getAccessToken(instance, account).then((token) => {
        setPaymentModal("PT");
        setToken(token);
        setOpen(true);
      });
    } else if (type === "CARD") {
      setPaymentModal("CARD");
      setOpen(true);
    } else if (type === "ACH") {
      setPaymentModal("ACH");
      setOpen(true);
    }
  };

  const onSuccess = () => {
    setPaymentModal(null);
    resetState();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentModal(null);
  };

  return (
    <>
      <div>
        <div>
          {/* Hidden for 10/1 */}
          {showVirtual() && (
            <button
              className="terminal-option"
              disabled={
                amount === 0 && paymentType !== PAYMENT_TYPES.VERIFICATION
              }
              onClick={() => {
                handlePaymentSelection("CARD");
              }}
            >
              <span>
                <CreditCardIcon />
              </span>
              <div>
                <p>Enter card information</p>
              </div>
            </button>
          )}

          {showACHPayment() && (
            <Tooltip
              title={
                paymentType === PAYMENT_TYPES.VERIFICATION ||
                paymentType === PAYMENT_TYPES.AUTHORIZATION
                  ? "Payment method not supported for the selected transaction type."
                  : ""
              }
            >
              <button
                className="terminal-option"
                disabled={
                  amount === 0 ||
                  paymentType === PAYMENT_TYPES.VERIFICATION ||
                  paymentType === PAYMENT_TYPES.AUTHORIZATION
                }
                onClick={() => {
                  handlePaymentSelection("ACH");
                }}
              >
                <span>
                  <AccountBalanceIcon />
                </span>
                <div>
                  <p>Enter bank information</p>
                </div>
              </button>
            </Tooltip>
          )}

          {showPhysical() && (
            // customer || paymentType === PAYMENT_TYPES.VERIFICATION
            <Tooltip
              title={
                customer
                  ? "Payment method not supported when a customer has been selected."
                  : paymentType === PAYMENT_TYPES.VERIFICATION
                    ? "Payment method not supported for the selected transaction type."
                    : ""
              }
            >
              <button
                className="terminal-option"
                disabled={
                  amount === 0 ||
                  paymentType === PAYMENT_TYPES.VERIFICATION ||
                  customer
                }
                onClick={() => {
                  handlePaymentSelection("PT");
                }}
              >
                <span>
                  <PointOfSaleOutlinedIcon />
                </span>
                <div>
                  <p>Select terminal</p>
                </div>
              </button>
            </Tooltip>
          )}
        </div>
      </div>
      <Dialog
        className={`payment-selection-modal v2 ${theme.palette.mode}-theme ${isFullWidth}`}
        maxWidth="lg"
        open={open}
      >
        {jwtToken && paymentModal === "PT" && (
          <PhysicalTerminalContainer
            handleClose={() => handleClose()}
            token={jwtToken}
            setFullWidth={setFullWidth}
            onSuccess={onSuccess}
          />
        )}
        {paymentModal === "CARD" && (
          <CardPaymentContainer
            handleClose={() => handleClose()}
            onSuccess={onSuccess}
            processors={gatewayInfo.processors.filter((p) => {
              return (
                p.processorType.certificationCategory === "VirtualTerminal" &&
                p.processorType.type === "CreditCard"
              );
            })}
          />
        )}
        {paymentModal === "ACH" && (
          <ACHPaymentContainer
            handleClose={() => handleClose()}
            onSuccess={onSuccess}
            processors={gatewayInfo.processors.filter((p) => {
              return p.processorType.type === "Ach";
            })}
          />
        )}
      </Dialog>
    </>
  );
};
PaymentMethodSelector.propTypes = {
  resetState: PropTypes.func,
};

export default PaymentMethodSelector;
