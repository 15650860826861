import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
import FormattedTextField from "../../../ReactHookForm/FormattedTextField";
import ACHTokenizerComponent from "../../../Tokenizer/ACHTokenizerCmp";
import { ADJUSTMENT_TYPES, readPaymentAdjustment } from "../terminalHelpers";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import { TerminalContext } from "../TerminalContext";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import PaymentAddress from "./PaymentAddress";
import AchIcon from "@mui/icons-material/AccountBalance";
const PaymentDetails = ({
  processors,
  defaultProcessor,
  paymentAdjustment,
  setPaymentAdjustment,
  billingAddress,
  handleSECChange,
  achSubmit,
  setAchSubmit,
  achToken,
  setAchToken,
  manualPayment,
  setManualPayment,
}) => {
  const gatewayInfo = useContext(GatewayContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const tokenizerConfiguration = useContext(TokenizerConfigContext);
  const form = useFormContext();
  const [showAdjustment, setShowAdjustment] = useState(
    readPaymentAdjustment(defaultProcessor.paymentAdjustments) !==
      ADJUSTMENT_TYPES.NONE,
  );
  const { customer } = useContext(TerminalContext);
  const customerPayments = customer
    ? customer?.paymentMethods?.filter((p) => {
        return p.ach;
      })
    : [];

  const defaultCustomer =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Virtual Terminal",
      "vault_record",
    )?.toLowerCase() === "true";

  const isSurcharge =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value.toLowerCase() === "true";

  const [saveCustomer, setCustomer] = useState(Boolean(defaultCustomer));

  const allowCustomerSave =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_CUSTOMER_VAULT_ACCESS")
      ?.value.toLowerCase() === "true";

  const availableSECCodes = [
    ...new Set(
      processors?.flatMap((p) => p?.features?.availableSecCodes ?? []),
    ),
  ];

  const handlePaymentAdjustmentChange = (event) => {
    setPaymentAdjustment(event);
  };

  const handleProcessorChange = (event) => {
    const newProcessor = processors.find((p) => {
      return p.processorId === event;
    });
    //
    setShowAdjustment(
      readPaymentAdjustment(newProcessor.paymentAdjustments) !==
        ADJUSTMENT_TYPES.NONE,
    );
    updateDefaultPaymentAdjustment(newProcessor, false);
  };

  const closeAdjustment = () => {
    setShowAdjustment(false);
    setPaymentAdjustment(ADJUSTMENT_TYPES.NONE);
  };
  const openAdjustment = () => {
    const currentProcessor =
      processors?.length > 1
        ? form.getValues("processors")
        : processors[0].processorId;

    const processorDetails = processors.find((p) => {
      return p.processorId === currentProcessor;
    });

    updateDefaultPaymentAdjustment(processorDetails, true);
    setShowAdjustment(true);
  };

  const updateDefaultPaymentAdjustment = (processor, newForm) => {
    if (
      readPaymentAdjustment(processor.paymentAdjustments) !==
      ADJUSTMENT_TYPES.NONE
    ) {
      const newValue =
        readPaymentAdjustment(processor.paymentAdjustments) ===
        ADJUSTMENT_TYPES.PERCENTAGE
          ? processor.paymentAdjustments[0]?.percentage * 1000
          : processor.paymentAdjustments[0]?.flatAmount * 100;
      setPaymentAdjustment(readPaymentAdjustment(processor.paymentAdjustments));
      form.setValue(
        "paymentAdjustmentType",
        readPaymentAdjustment(processor.paymentAdjustments),
      );
      form.setValue("paymentAdjustmentValue", newValue);
    } else if (newForm) {
      setPaymentAdjustment(ADJUSTMENT_TYPES.PERCENTAGE);
      form.setValue("paymentAdjustmentType", ADJUSTMENT_TYPES.PERCENTAGE);
      form.setValue("paymentAdjustmentValue", 0);
    } else {
      setPaymentAdjustment(ADJUSTMENT_TYPES.NONE);
    }
  };

  return (
    <div className="payment-details">
      <div className="combination-inputs" style={{ marginBottom: "16px" }}>
        <Select
          name={`currency`}
          control={form.control}
          label="Amount"
          defaultValue={"USD"}
          disabled={true}
          errors={form.formState.errors}
          v2={true}
        >
          <MenuItem value="USD">USD</MenuItem>
        </Select>
        <FormattedTextField
          type="currency"
          name="amount"
          defaultValue={form.getValues("amount")}
          rules={{
            min: { value: 1 },
            max: {},
            required: true,
          }}
        />
      </div>
      {!isSurcharge && (
        <>
          {showAdjustment ? (
            <div
              style={{ marginBottom: "16px" }}
              className="combination-inputs"
            >
              <Select
                control={form.control}
                name="paymentAdjustmentType"
                label="Adjustment"
                defaultValue={paymentAdjustment}
                v2={true}
                onChange={handlePaymentAdjustmentChange}
              >
                <MenuItem value={ADJUSTMENT_TYPES.PERCENTAGE}>%</MenuItem>
                <MenuItem value={ADJUSTMENT_TYPES.FLAT}>$</MenuItem>
              </Select>
              <FormattedTextField
                type={
                  paymentAdjustment.toLowerCase() === ADJUSTMENT_TYPES.FLAT
                    ? "currency"
                    : paymentAdjustment
                }
                name="paymentAdjustmentValue"
                defaultValue={
                  readPaymentAdjustment(defaultProcessor.paymentAdjustments) ===
                  ADJUSTMENT_TYPES.FLAT
                    ? defaultProcessor?.paymentAdjustments[0]?.flatAmount * 100
                    : defaultProcessor?.paymentAdjustments[0]?.percentage *
                        1000 || 0
                }
              />
              <IconButton
                sx={{ paddingBottom: "0px" }}
                onClick={() => {
                  closeAdjustment();
                }}
              >
                <ClearIcon color="error" />
              </IconButton>
            </div>
          ) : (
            <button
              className="btn--link"
              onClick={() => {
                openAdjustment(true);
              }}
            >
              Add Payment Adjustment +
            </button>
          )}
        </>
      )}

      <Select
        name={`processors`}
        control={form.control}
        label="Processors"
        defaultValue={defaultProcessor.processorId}
        rules={{ required: true }}
        errors={form.formState.errors}
        v2={true}
        style={{ marginBottom: "16px" }}
        onChange={(e) => {
          handleProcessorChange(e);
        }}
      >
        {processors.map((p, i) => {
          return (
            <MenuItem key={i} value={p.processorId}>
              {p.name}
            </MenuItem>
          );
        })}
      </Select>
      <div style={{ marginBottom: "8px" }}>
        <h2 className="h4">Payment Details</h2>
        {customer && customerPayments.length > 0 ? (
          <>
            <FormControlLabel
              label="Charge payment method on file."
              labelPlacement="end"
              checked={!manualPayment}
              disabled={customerPayments.length === 0}
              onChange={() => {
                setManualPayment(false);
              }}
              control={<Radio />}
              sx={{ width: "100%" }}
            />
            {!manualPayment && (
              <div>
                {customerPayments.length > 1 ? (
                  <div>
                    <Select
                      name={`paymentMethod`}
                      control={form.control}
                      label="Payment Options"
                      defaultValue={
                        customerPayments.find((p) => {
                          return p.isDefault;
                        })?.paymentMethodId ||
                        customerPayments[0]?.paymentMethodId
                      }
                      rules={{ required: true }}
                      errors={form.formState.errors}
                      v2={true}
                      sx={{ marginBottom: "8px" }}
                    >
                      {customerPayments.map((p, i) => {
                        return (
                          <MenuItem key={i} value={p.paymentMethodId}>
                            {p.ach.accountType + " / " + p.ach.maskedAccount}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                ) : (
                  <div>
                    <div
                      role="table"
                      aria-label="Payment Method"
                      className="payment-method-row"
                      aria-describedby="method-description"
                    >
                      <div style={{ display: "none" }} id="method-description">
                        Customer bank payment method on file
                      </div>
                      <div role="row" className="header">
                        <span role="columnheader" aria-sort="none">
                          Account Number
                        </span>
                        <span role="columnheader" aria-sort="none">
                          Account Type
                        </span>
                        <span role="columnheader" aria-sort="none">
                          Default
                        </span>
                      </div>
                      <div role="row">
                        <span role="cell" style={{ display: "flex" }}>
                          {" "}
                          <AchIcon /> {customerPayments[0]?.ach?.maskedAccount}
                        </span>
                        <span role="cell">
                          {customerPayments[0]?.ach?.accountType}
                        </span>
                        <span role="cell">
                          {customerPayments[0].isDefault ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <FormControlLabel
              label="Manually enter bank details."
              labelPlacement="end"
              checked={manualPayment}
              onChange={() => {
                setManualPayment(true);
              }}
              sx={{ width: "100%" }}
              control={<Radio />}
            />

            <div className={!manualPayment && "tokenizer-hidden"}>
              <div id="tokenizer-ach"></div>
              {tokenizerConfiguration.achTokenizer === "IQPROV2" &&
                tokenizerConfiguration?.iframeConfiguration?.iqProV2 && (
                  <>
                    {manualPayment && (
                      <ACHTokenizerComponent
                        handleAchChange={handleSECChange}
                        achSubmit={achSubmit}
                        setAchSubmit={setAchSubmit}
                        achToken={achToken}
                        setAchToken={setAchToken}
                        availableSEC={availableSECCodes}
                      />
                    )}
                  </>
                )}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div id="tokenizer-ach"></div>
            {tokenizerConfiguration.achTokenizer === "IQPROV2" &&
              tokenizerConfiguration?.iframeConfiguration?.iqProV2 && (
                <ACHTokenizerComponent
                  handleAchChange={handleSECChange}
                  achSubmit={achSubmit}
                  setAchSubmit={setAchSubmit}
                  achToken={achToken}
                  setAchToken={setAchToken}
                  availableSEC={availableSECCodes}
                />
              )}
            {allowCustomerSave && !customer && (
              <div>
                <FormControlLabel
                  name={"saveCustomer"}
                  sx={{ marginBottom: "12px" }}
                  control={
                    <Checkbox
                      color="secondary"
                      defaultChecked={saveCustomer}
                      value={saveCustomer}
                      {...form.register("saveCustomer")}
                      onClick={() => {
                        setCustomer(!saveCustomer);
                      }}
                    />
                  }
                  label={"Save customer and payment method for future use"}
                />
                {saveCustomer && (
                  <RegisteredTextField
                    name={"customerName"}
                    label="Customer Name"
                    fullWidth
                    rules={{
                      pattern: validationRules.alphanumericSpace,
                      maxLength: validationRules.maxLength100,
                      required: true,
                    }}
                    v2={true}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <PaymentAddress billingAddress={billingAddress} />
    </div>
  );
};

PaymentDetails.propTypes = {
  processors: PropTypes.array,
  defaultProcessor: PropTypes.object,
  paymentAdjustment: PropTypes.string,
  setPaymentAdjustment: PropTypes.func,
  billingAddress: PropTypes.object,
  handleSECChange: PropTypes.func,
  achSubmit: PropTypes.bool,
  setAchSubmit: PropTypes.func,
  achToken: PropTypes.string,
  setAchToken: PropTypes.func,
  manualPayment: PropTypes.bool,
  setManualPayment: PropTypes.func,
};

export default PaymentDetails;
