import React, { useContext, useRef, useState, useEffect } from "react";
import "../../../../assets/v2/main.scss";
import "../PhysicalTerminal/physicalTerminal.scss";
import "./cardPayment.scss";
import PaymentDetails from "./PaymentDetails";
import CardReview from "./PaymentReview";
import PaymentSuccess from "./TransactionSuccess";
import { useFormContext } from "react-hook-form";
import { TerminalContext } from "../TerminalContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import axios from "axios";
import PropTypes from "prop-types";
import {
  formatAddresses,
  ADJUSTMENT_TYPES,
  checkRequired,
} from "../terminalHelpers";
import { iqProVer, TRANSACTION_RESPONSES } from "../../../../constants/global";
import { useTokenizer } from "../../../../hooks/useTokenizer";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
import {
  calculateTotal,
  handleCustomField,
  readPaymentAdjustment,
} from "../terminalHelpers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const CardPaymentContainer = ({ onSuccess, handleClose, processors }) => {
  const [transactionState, setTransactionState] = useState({
    isSuccess: false,
    transactionData: null,
  });
  const defaultProcessor =
    processors.find((p) => {
      return p.isDefaultCard;
    }) || processors[0];
  const [errorMessage, setErrorMessage] = useState(null);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [paymentAdjustment, setPaymentAdjustment] = useState(
    readPaymentAdjustment(defaultProcessor.paymentAdjustments),
  );
  const [paymentDetails, setPaymentDetails] = useState(null);
  const form = useFormContext();
  const { paymentType, taxRules, customer } = useContext(TerminalContext);
  const [manualPayment, setManualPayment] = useState(
    customer?.paymentMethods?.filter((p) => {
      return p.card;
    }).length > 0
      ? false
      : true,
  );
  const gatewayConfig = useContext(GatewayConfigContext);
  const gatewayInfo = useContext(GatewayContext);
  const { userSettings } = useContext(UserSettingsContext);
  const tokenizerConfiguration = useContext(TokenizerConfigContext);
  const tokenizerV2 = useRef();
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;
  const isSurcharge =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value.toLowerCase() === "true";

  useEffect(() => {
    if (paymentDetails) {
      let payload = constructPayload();
      payload.paymentMethod = paymentDetails;
      submitTransaction(payload);
    }
  }, [paymentDetails]);

  useEffect(() => {
    return () => {
      form.unregister("paymentAdjustmentType");
      form.unregister("paymentAdjustmentValue");
      form.unregister("processors");
      form.unregister("bin");
      form.unregister("billing");
      form.unregister("shipping");
    };
  }, []);

  const handleOnValidCard = (card) => {
    // if (card?.bin?.bin?.length === 6) {
    //   setCardBinNumber(card?.bin?.bin);
    // }
    if (form.getValues().bin) {
      form.setValue("bin", card?.bin?.bin);
    } else {
      form.register("bin");
      form.setValue("bin", card?.bin?.bin);
    }
  };

  const handleFluidPay = (response) => {
    if (response.status === "success") {
      const cardPayment = {
        card: {
          cardToken: response.token,
          maskedNumber: form.getValues().bin.substring(0, 6) + "******1111",
        },
      };
      setPaymentDetails(cardPayment);
    }
  };

  const customerTransaction = () => {
    const payments = customer?.paymentMethods?.filter((p) => {
      return p.card;
    });
    const methodId =
      payments.length > 1
        ? form.getValues("paymentMethod")
        : payments[0].paymentMethodId;
    const customerDetails = {
      customer: {
        customerId: customer.customerId,
        customerPaymentMethodId: methodId,
      },
    };
    setPaymentDetails(customerDetails);
  };

  const cardTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig.gatewayConfigSettings,
      "required_fields",
      "Default",
      "payment_method_card",
    ) !== "hidden" && tokenizerConfiguration.cardTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: "card",
          apikey: userSettings.publicKey,
          onSubmit: handleFluidPay,
          onValidCard: handleOnValidCard,
          container: "#tokenizer-card",
          showCvv: true,
        })
      : null;

  const submitPayment = () => {
    setErrorMessage("");
    form.trigger().then(() => {
      if (checkRequired(form?.formState?.errors)) {
        setErrorMessage("Please fill in all required fields.");
      } else {
        if (customer && !manualPayment) {
          customerTransaction();
        } else {
          tokenizerConfiguration.cardTokenizer === "IQPROV2"
            ? tokenizerV2?.current?.validate()
            : cardTokenizer?.submit();
        }
      }
    });
  };

  const constructPayload = () => {
    const formValues = form.getValues();
    return {
      type: paymentType,
      orderId: formValues?.orderId || "",
      poNumber: formValues?.poNumber || "",
      description: formValues?.description,
      source: "API",
      vaultCustomer: formValues.saveCustomer,
      customerName: formValues.customerName,
      emailReceipt: "",
      emailAddress: "",
      remit: {
        baseAmount: formatCurrency(formValues.amount),
        taxAmount:
          !taxRules.taxExempt && taxRate
            ? formatCurrency((formValues.amount * (taxRate / 100)).toFixed(2))
            : 0,
        taxExempt: taxRules.taxExempt,
        currencyCode: "USD",
        tip: 0,
        paymentAdjustments: formatPaymentAdjustments(),
        surchargeable: isSurcharge,
        addTaxToTotal: taxRules.taxToTotal,
      },
      ...(formValues.customer_field_group_select && {
        customFields: handleCustomField(
          form.getValues("custom_fields"),
          form.getValues("customer_field_group_select"),
        ),
      }),
      ...(customer &&
        manualPayment && {
          paymentMethods: {
            customer: {
              customerId: customer.customerId,
            },
          },
        }),
      ...(customer && {
        name: customer.name,
      }),
      processorId:
        processors?.length > 1
          ? formValues?.processors
          : processors[0].processorId,
      address: formatAddresses(formValues),
    };
  };
  const formatPaymentAdjustments = () => {
    let paymentAdjustments = [];
    if (!taxRules.taxExempt && taxRate) {
      const tax = {
        type: "Tax",
        flatAmount: formatCurrency(
          (form.getValues("amount") * (taxRate / 100)).toFixed(2),
        ),
      };
      paymentAdjustments.push(tax);
    }
    if (paymentAdjustment !== ADJUSTMENT_TYPES.NONE) {
      const processorAmount = form.getValues("paymentAdjustmentValue");
      const processorAdjustment = {
        type:
          paymentAdjustment === ADJUSTMENT_TYPES.PERCENTAGE
            ? "ServiceFee"
            : "ConvenienceFee",
        ...(paymentAdjustment === ADJUSTMENT_TYPES.FLAT && {
          flatAmount: (processorAmount / 100).toFixed(2),
        }),
        ...(paymentAdjustment === ADJUSTMENT_TYPES.PERCENTAGE && {
          percentage: (processorAmount / 1000).toFixed(3),
        }),
      };
      paymentAdjustments.push(processorAdjustment);
    }
    return paymentAdjustments;
  };

  const handleTokenEx = (data, exp) => {
    const cardDetails = {
      card: {
        cardToken: data.token,
        expirationDate: exp,
        maskedNumber: data.firstSix + "******" + data.lastFour,
      },
    };
    setPaymentDetails(cardDetails);
  };

  const submitTransaction = (payload) => {
    setSpinnerOpen(true);
    let url = stringFormat(sassEndpoints.transactions.transaction, [
      userSettings.gatewayId,
    ]);
    axios
      .post(url, payload)
      .then(function (response) {
        if (
          response?.data?.data?.status?.toLowerCase() ===
          TRANSACTION_RESPONSES.DECLINED
        ) {
          setErrorMessage(
            response?.data?.data?.processorResponseCode +
              " - " +
              response?.data?.data?.processorResponseText,
          );
        } else {
          const total = calculateTotal(
            form.getValues().amount,
            taxRules.taxToTotal ? taxRate : 0,
            paymentAdjustment,
            form.getValues()?.paymentAdjustmentValue,
          );
          if (response?.status === 207) {
            setTransactionState({
              isSuccess: true,
              transactionData: { statusCode: 207, total: total },
            });
          } else {
            response.data.data.total = total;
            setTransactionState({
              isSuccess: true,
              transactionData: response?.data?.data,
            });
          }
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setSpinnerOpen(false);
      });
  };

  const formatCurrency = (amount) => {
    return Number((amount / 100).toFixed(2));
  };

  return (
    <div>
      <div className="sub-wrapper card-payment">
        {transactionState.isSuccess ? (
          <PaymentSuccess
            transactionData={transactionState.transactionData}
            returnToTransaction={onSuccess}
          />
        ) : (
          <>
            <PaymentDetails
              tokenizerRef={tokenizerV2}
              handleTokenEx={handleTokenEx}
              paymentAdjustment={paymentAdjustment}
              setPaymentAdjustment={setPaymentAdjustment}
              processors={processors}
              defaultProcessor={defaultProcessor}
              manualPayment={manualPayment}
              setManualPayment={setManualPayment}
            />
            <CardReview paymentAdjustment={paymentAdjustment} />
            <button
              onClick={submitPayment}
              style={{ marginBottom: "12px" }}
              className="btn btn--primary"
            >
              Submit
            </button>
            <button onClick={handleClose} className="btn btn--secondary">
              Cancel
            </button>
            {errorMessage && (
              <div
                style={{ marginTop: "16px", width: "100%" }}
                className={`tag tag__failed`}
              >
                <p>{errorMessage}</p>
              </div>
            )}
          </>
        )}
      </div>
      <Backdrop
        open={spinnerOpen}
        transitionDuration={0}
        sx={{ color: "#fff", zIndex: 9000, opacity: 0.5 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
CardPaymentContainer.propTypes = {
  onSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  processors: PropTypes.array,
};
export default CardPaymentContainer;
