import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { FormProvider } from "react-hook-form";
import Content from "../../Content/Content";
import ContactContainer from "../../Contact/ContactContainer";
import PaymentDetails from "./PaymentDetails";
import Submit from "./Submit";
import Payment from "./Payment";
import { CustomFields } from "../CustomFields/GlobalCustomFields";
import { LABEL } from "./PaymentTabs";
import BasicCard from "../../Cards/BasicCard";
import AccordionCard from "../../Cards/AccordionCard";
import { useEffect, useState } from "react";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { useHistory } from "react-router-dom";
import { merchantRoutes } from "../../../constants/routes";
import Switch from "../../ReactHookForm/Switch";
const VirtualTerminal = ({
  isInitialPageLoad,
  showBackDrop,
  isCustomerInfoLoading,
  onSubmit,
  onError,
  customerInfo,
  paymentOption,
  setPaymentOption,
  useLineItems,
  methods,
  isAlertOpen,
  setIsAlertOpen,
  alertMessages,
  setTokenizer,
  tokenizerApiKey,
  handleTokenizerSubmission,
  gatewayConfig,
  gatewayInfo,
  processors,
  setRequiredContactFields,
  requiredContactFields,
  customFieldGroups,
  chosenCustomFields,
  countries,
  defaultAddresses,
  setDefaultAddresses,
  selectedPaymentTab,
  setSelectedPaymentTab,
  achToken,
  setAchToken,
  achSubmit,
  setAchSubmit,
  tokenizerV2,
  handleSaasTokenizerSubmission,
}) => {
  const [requiredBillingFields, setRequiredBillingFields] = useState([]);
  const [requiredShippingFields, setRequiredShippingFields] = useState([]);
  const [selectedProcessorId, setSelectedProcessorId] = useState("");
  const [showSurchargeMsg, setShowSurchargeMsg] = useState(null);
  const [vaultCustomer, setVaultCustomer] = useState(false);
  const [newRequiredBilling, setNewRequiredBilling] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (
      Object.keys(gatewayConfig).length > 0 &&
      Object.keys(gatewayInfo).length > 0
    ) {
      setRequiredBillingFields(setupMerchantSettings(true, true));
      setRequiredShippingFields(setupMerchantSettings(false, true));
      if (requiredContactFields.length > 0) {
        let requirements = setupMerchantSettings(true, true);
        requiredContactFields.forEach((newField) => {
          if (
            !requirements.find((field) => {
              return field === newField;
            })
          ) {
            requirements.push(newField);
          }
        });
        setRequiredBillingFields(requirements);
      }
    }
  }, [requiredContactFields, gatewayConfig, gatewayInfo]);

  useEffect(() => {
    if (vaultCustomer === true) {
      let newRequiredBilling = Array.from(requiredBillingFields);
      if (!requiredBillingFields.includes("firstName")) {
        newRequiredBilling.push("firstName");
      }
      if (!requiredBillingFields.includes("lastName")) {
        newRequiredBilling.push("lastName");
      }
      if (!requiredBillingFields.includes("addressLine1"))
        newRequiredBilling.push("address1");
      if (!requiredBillingFields.includes("state"))
        newRequiredBilling.push("state");
      if (!requiredBillingFields.includes("city"))
        newRequiredBilling.push("city");
      if (!requiredBillingFields.includes("postalCode"))
        newRequiredBilling.push("postalCode");
      setNewRequiredBilling(newRequiredBilling);
    } else {
      setNewRequiredBilling(null);
    }
  }, [vaultCustomer]);

  const setupMerchantSettings = (isBilling, isRequired) => {
    const parseToArray = (requiredObject) => {
      let arr = [];
      Object.entries(requiredObject).forEach((key) => {
        if (key[1]) {
          arr.push(key[0]);
        }
      });
      return arr;
    };
    if (isBilling) {
      const form = {
        address1: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_address_line_1",
            ) === "required" ||
            gatewayInfo.gatewaySettings
              .find((s) => s.code === "ALLOW_SURCHARGE")
              ?.value.toLowerCase() === "true"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_address_line_1",
            ) === "hidden",
        address2: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_address_line_2",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_address_line_2",
            ) === "hidden",
        city: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_city",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_city",
            ) === "hidden",
        country: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_country",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_country",
            ) === "hidden",
        state: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_state",
            ) === "required" ||
            gatewayInfo.gatewaySettings
              .find((s) => s.code === "ALLOW_SURCHARGE")
              ?.value.toLowerCase() === "true"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_state",
            ) === "hidden",
        company: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_company",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_company",
            ) === "hidden",
        email: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_email",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_email",
            ) === "hidden",
        fax: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_fax",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_fax",
            ) === "hidden",
        firstName: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_first_name",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_first_name",
            ) === "hidden",
        lastName: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_last_name",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_last_name",
            ) === "hidden",
        phone: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_phone",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_phone",
            ) === "hidden",
        postalCode: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_postal_code",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "billing_postal_code",
            ) === "hidden",
      };
      return isRequired ? parseToArray(form) : form;
    } else {
      const form = {
        address1: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_address_line_1",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_address_line_1",
            ) === "hidden",
        address2: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_address_line_2",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_address_line_2",
            ) === "hidden",
        city: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_city",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_city",
            ) === "hidden",
        country: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_country",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_country",
            ) === "hidden",
        state: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_state",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_state",
            ) === "hidden",
        company: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_company",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_company",
            ) === "hidden",
        email: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_email",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_email",
            ) === "hidden",
        fax: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_fax",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_fax",
            ) === "hidden",
        firstName: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_first_name",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_first_name",
            ) === "hidden",
        lastName: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_last_name",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_last_name",
            ) === "hidden",
        phone: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_phone",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_phone",
            ) === "hidden",
        postalCode: isRequired
          ? getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_postal_code",
            ) === "required"
          : getGatewayConfigFieldVal(
              gatewayConfig,
              "required_fields",
              "Default",
              "shipping_postal_code",
            ) === "hidden",
      };
      return isRequired ? parseToArray(form) : form;
    }
  };
  const toggleVirtualTerminal = () => {
    localStorage.setItem("VT2", true);
    history.push({
      pathname: `${merchantRoutes.terminal}`,
    });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Content
          spinnerOpen={isInitialPageLoad || showBackDrop}
          useFixedWidth={true}
          title={"Virtual Terminal"}
          previewToggle={
            isInitialPageLoad ? null : gatewayInfo.gatewaySettings
                .find((s) => s.code === "ALLOW_SURCHARGE")
                ?.value.toLowerCase() === "true" ? null : (
              <Switch
                control={methods.control}
                label={"New Terminal"}
                labelPlacement="start"
                name={"terminal-preview"}
                defaultValue={false}
                onChange={() => {
                  toggleVirtualTerminal();
                }}
              />
            )
          }
          bodyContent={
            isInitialPageLoad ? null : (
              <Grid container spacing={2} sx={{ pr: 1 }}>
                <Grid item sm={12} md={6}>
                  <Payment
                    paymentOption={paymentOption}
                    setPaymentOption={setPaymentOption}
                    useLineItems={useLineItems}
                    setRequiredContactFields={setRequiredContactFields}
                    setTokenizer={setTokenizer}
                    tokenizerApiKey={tokenizerApiKey}
                    handleTokenizerSubmission={handleTokenizerSubmission}
                    gatewayConfig={gatewayConfig}
                    gatewayInfo={gatewayInfo}
                    isCustomerLoading={isCustomerInfoLoading}
                    customerInfo={customerInfo}
                    setDefaultAddresses={setDefaultAddresses}
                    selectedPaymentTab={selectedPaymentTab}
                    setSelectedPaymentTab={setSelectedPaymentTab}
                    selectedProcessorId={selectedProcessorId}
                    achSubmit={achSubmit}
                    setAchSubmit={setAchSubmit}
                    achToken={achToken}
                    setAchToken={setAchToken}
                    setShowSurchargeMsg={setShowSurchargeMsg}
                    tokenizerV2={tokenizerV2}
                    handleSaasTokenizerSubmission={
                      handleSaasTokenizerSubmission
                    }
                    setIsAlertOpen={setIsAlertOpen}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PaymentDetails
                    paymentOption={paymentOption}
                    gatewayConfig={gatewayConfig}
                    gatewayInfo={gatewayInfo}
                    processors={processors}
                    setSelectedProcessorId={setSelectedProcessorId}
                    control={methods.control}
                    vaultRecordHidden={selectedPaymentTab === LABEL.CUSTOMER}
                    setVaultCustomer={setVaultCustomer}
                  />
                </Grid>
                {customFieldGroups?.length > 0 &&
                  chosenCustomFields?.length > 0 && (
                    <Grid item xs={12}>
                      <AccordionCard
                        title="Custom Fields"
                        defaultExpanded={true}
                      >
                        <CustomFields
                          customFieldGroups={customFieldGroups}
                          chosenCustomFields={chosenCustomFields}
                        />
                      </AccordionCard>
                    </Grid>
                  )}
                <Grid item lg={6} md={6} xs={12} sx={{ marginBottom: "1em" }}>
                  <BasicCard title="Billing Contact">
                    <ContactContainer
                      type="billing_address"
                      countries={countries}
                      country="US"
                      state="IL"
                      required={
                        newRequiredBilling
                          ? newRequiredBilling
                          : requiredBillingFields
                      }
                      defaultValues={defaultAddresses?.billing}
                      hidden={setupMerchantSettings(true, false)}
                    />
                  </BasicCard>
                </Grid>
                <Grid item lg={6} md={6} xs={12} sx={{ marginBottom: "1em" }}>
                  <BasicCard title="Shipping Contact">
                    <ContactContainer
                      type="shipping_address"
                      required={requiredShippingFields}
                      countries={countries}
                      defaultValues={defaultAddresses?.shipping}
                      hidden={setupMerchantSettings(false, false)}
                    />
                  </BasicCard>
                </Grid>
              </Grid>
            )
          }
          footerContent={
            <Submit
              handleSubmit={() =>
                methods
                  .trigger()
                  .then((valid) =>
                    valid ? onSubmit(methods.getValues()) : onError(),
                  )
              }
              control={methods.control}
              showSurchargeMsg={showSurchargeMsg}
            />
          }
          alertDialogOpen={isAlertOpen}
          alertDialogProps={{
            alertTitle: "Error!",
            alertLevel: "error",
            alertMessages: alertMessages,
            closeButtonText: "Ok",
            onCloseButtonClick: () => setIsAlertOpen(false),
          }}
        />
      </form>
    </FormProvider>
  );
};

VirtualTerminal.propTypes = {
  isInitialPageLoad: PropTypes.bool.isRequired,
  showBackDrop: PropTypes.bool.isRequired,
  isCustomerInfoLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  customerInfo: PropTypes.object,
  paymentOption: PropTypes.string.isRequired,
  setPaymentOption: PropTypes.func.isRequired,
  useLineItems: PropTypes.bool.isRequired,
  methods: PropTypes.object.isRequired,
  isAlertOpen: PropTypes.bool,
  setIsAlertOpen: PropTypes.func,
  setTokenizer: PropTypes.func.isRequired,
  alertMessages: PropTypes.array.isRequired,
  tokenizerApiKey: PropTypes.string.isRequired,
  handleTokenizerSubmission: PropTypes.func.isRequired,
  gatewayConfig: PropTypes.object.isRequired,
  gatewayInfo: PropTypes.object.isRequired,
  processors: PropTypes.array,
  setRequiredContactFields: PropTypes.func.isRequired,
  requiredContactFields: PropTypes.array.isRequired,
  customFieldGroups: PropTypes.array,
  chosenCustomFields: PropTypes.array,
  countries: PropTypes.array.isRequired,
  defaultAddresses: PropTypes.object,
  setDefaultAddresses: PropTypes.func,
  selectedPaymentTab: PropTypes.string,
  setSelectedPaymentTab: PropTypes.func,
  achToken: PropTypes.string,
  setAchToken: PropTypes.func,
  achSubmit: PropTypes.bool,
  setAchSubmit: PropTypes.func,
  tokenizerV2: PropTypes.any,
  handleSaasTokenizerSubmission: PropTypes.func,
};

export default VirtualTerminal;
