import React, { useContext } from "react";
import "../../../../assets/v2/main.scss";
import "../PhysicalTerminal/physicalTerminal.scss";
import { useFormContext } from "react-hook-form";
import { formatCurrency } from "../../../../utils/stringHelpers";
import { TerminalContext } from "../TerminalContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { calculateTotal, ADJUSTMENT_TYPES } from "../terminalHelpers";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
const CardReview = ({ paymentAdjustment }) => {
  const form = useFormContext();
  const { taxRules, customer } = useContext(TerminalContext);
  const amount = form.getValues("amount");
  const paymentAdjustmentValue = useWatch({ name: "paymentAdjustmentValue" });
  const gatewayConfig = useContext(GatewayConfigContext);
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;
  return (
    <div style={{ marginBottom: "16px" }}>
      <div className="totals-section">
        {customer && <p className="customer-name">{customer.name}</p>}
        <div>
          <span>Subtotal</span>
          <span>{formatCurrency(amount)}</span>
        </div>
        {!taxRules.taxExempt && taxRate && amount > 0 && (
          <>
            {((amount / 100) * (taxRate / 100)).toFixed(2) > 0 && (
              <div>
                <span>Tax %{taxRate}</span>
                <span>
                  {formatCurrency((amount * (taxRate / 100)).toFixed(2))}
                </span>
              </div>
            )}
          </>
        )}
        {paymentAdjustment !== ADJUSTMENT_TYPES.NONE && (
          <div>
            <span>Payment Adjustment</span>
            <span>
              {paymentAdjustment === ADJUSTMENT_TYPES.FLAT
                ? formatCurrency(paymentAdjustmentValue ?? 0)
                : formatCurrency(
                    calculateTotal(
                      amount,
                      taxRules.taxToTotal ? taxRate : 0,
                      paymentAdjustment,
                      paymentAdjustmentValue,
                      true,
                    ),
                  )}
            </span>
          </div>
        )}
      </div>
      <div className="total">
        <span>Total</span>
        <span>
          {" "}
          {formatCurrency(
            calculateTotal(
              amount,
              taxRules.taxToTotal ? taxRate : 0,
              paymentAdjustment,
              paymentAdjustmentValue,
            ),
          )}
        </span>
      </div>
    </div>
  );
};
CardReview.propTypes = {
  paymentAdjustment: PropTypes.string,
};

export default CardReview;
