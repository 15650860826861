import React, { useContext, useState, useEffect } from "react";
import "../../../../assets/v2/main.scss";
import "../PhysicalTerminal/physicalTerminal.scss";
import "./achPayment.scss";
import PaymentDetails from "./PaymentDetails";
import PaymentReview from "./PaymentReview";
import PropTypes from "prop-types";
import { getGatewayConfigFieldVal } from "../../../../utils/objectHelpers";
import { iqProVer, TRANSACTION_RESPONSES } from "../../../../constants/global";
import { useTokenizer } from "../../../../hooks/useTokenizer";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import { GatewayConfigContext } from "../../../../contexts/GatewayConfigContext";
import { TokenizerConfigContext } from "../../../../contexts/TokenizerConfigContext";
import { TerminalContext } from "../TerminalContext";
import { useFormContext } from "react-hook-form";
import {
  formatAddresses,
  calculateTotal,
  ADJUSTMENT_TYPES,
  defaultHiddenFields,
  handleCustomField,
  readPaymentAdjustment,
} from "../terminalHelpers";
import PaymentSuccess from "./TransactionSuccess";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import axios from "axios";
import { deepCopyFunction } from "../../../../utils/objectHelpers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const ACHPaymentContainer = ({ processors, onSuccess, handleClose }) => {
  const [transactionState, setTransactionState] = useState({
    isSuccess: false,
    transactionData: null,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [billingAddress, setBillingAddress] = useState(defaultHiddenFields);
  const [achSubmit, setAchSubmit] = useState(false);
  const [achToken, setAchToken] = useState(null);
  const { userSettings } = useContext(UserSettingsContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const tokenizerConfiguration = useContext(TokenizerConfigContext);
  const { paymentType, taxRules, customer } = useContext(TerminalContext);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const defaultProcessor =
    processors.find((p) => {
      return p.isDefaultAch;
    }) || processors[0];

  const [paymentAdjustment, setPaymentAdjustment] = useState(
    readPaymentAdjustment(defaultProcessor.paymentAdjustments),
  );
  const [manualPayment, setManualPayment] = useState(
    customer?.paymentMethods?.filter((p) => {
      return p.ach;
    }).length > 0
      ? false
      : true,
  );
  const [paymentDetails, setPaymentDetails] = useState(null);
  const form = useFormContext();
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;

  const availableSECCodes = [
    ...new Set(
      processors?.flatMap((p) => p?.features?.availableSecCodes ?? []),
    ),
  ];

  useEffect(() => {
    if (paymentDetails) {
      let payload = constructPayload();
      payload.paymentMethod = paymentDetails;
      submitTransaction(payload);
    }
  }, [paymentDetails]);

  useEffect(() => {
    return () => {
      form.unregister("paymentAdjustmentType");
      form.unregister("paymentAdjustmentValue");
      form.unregister("processors");
      form.unregister("billing");
      form.unregister("shipping");
    };
  }, []);

  const handleSECChange = (data) => {
    tokenizerConfiguration.achTokenizer === iqProVer.v2
      ? validSECFields(defaultHiddenFields, data)
      : validSECFields(defaultHiddenFields, data.sec_code);
  };

  const validSECFields = (fields, secCode) => {
    let newFields = deepCopyFunction(fields);
    const code = secCode.toUpperCase();
    switch (code) {
      case "CCD": {
        newFields.company = false;
        break;
      }
      case "WEB": {
        newFields.email = false;
        break;
      }
      case "TEL": {
        newFields.phone = false;
        break;
      }
    }
    setBillingAddress(newFields);
  };

  useEffect(() => {
    if (achToken) {
      setAchSubmit(false);
      handleTokenizerSubmission({ status: "success", token: achToken });
    }
  }, [achToken]);

  const customerTransaction = () => {
    const payments = customer.paymentMethods.filter((p) => {
      return p.ach;
    });
    const methodId =
      payments.length > 1
        ? form.getValues("paymentMethod")
        : payments[0].paymentMethodId;
    const customerDetails = {
      customer: {
        customerId: customer.customerId,
        customerPaymentMethodId: methodId,
      },
    };
    setPaymentDetails(customerDetails);
  };

  const submitPayment = () => {
    setErrorMessage("");
    form.trigger().then(() => {
      if (checkRequired(form?.formState?.errors)) {
        setErrorMessage("Please fill in all required fields.");
      } else {
        if (customer && !manualPayment) {
          customerTransaction();
        } else {
          tokenizerConfiguration.achTokenizer === iqProVer.v2
            ? setAchSubmit(true)
            : achTokenizer.submit();
        }
      }
    });
  };

  const checkRequired = (errors) => {
    for (const [k, v] of Object.entries(errors)) {
      if (errors[k] === "required") {
        return true;
      }
      if (v && typeof v === "object") {
        if (checkRequired(v)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleTokenizerSubmission = (response) => {
    if (response.status === "success") {
      const achDetails = {
        ach: {
          achToken: response.token,
        },
      };
      setPaymentDetails(achDetails);
    }
  };

  const constructPayload = () => {
    const formValues = form.getValues();
    return {
      type: paymentType,
      orderId: formValues?.orderId || "",
      poNumber: formValues?.poNumber || "",
      description: formValues?.description,
      source: "API",
      vaultCustomer: formValues.saveCustomer,
      customerName: formValues.customerName,
      emailReceipt: "",
      emailAddress: "",
      remit: {
        baseAmount: formatCurrency(formValues.amount),
        taxAmount:
          !taxRules.taxExempt && taxRate
            ? formatCurrency((formValues.amount * (taxRate / 100)).toFixed(2))
            : 0,
        taxExempt: taxRules.taxExempt,
        currencyCode: "USD",
        tip: 0,
        addTaxToTotal: taxRules.taxToTotal,
        paymentAdjustments: formatPaymentAdjustments(),
      },
      processorId:
        processors?.length > 1
          ? formValues?.processors
          : processors[0].processorId,
      ...(formValues.customer_field_group_select && {
        customFields: handleCustomField(
          form.getValues("custom_fields"),
          form.getValues("customer_field_group_select"),
        ),
      }),
      ...(customer &&
        manualPayment && {
          paymentMethods: {
            customer: {
              customerId: customer.customerId,
            },
          },
        }),
      ...(customer && {
        name: customer.name,
      }),
      address: formatAddresses(formValues),
    };
  };
  const formatPaymentAdjustments = () => {
    let paymentAdjustments = [];
    if (!taxRules.taxExempt && taxRate) {
      const tax = {
        type: "Tax",
        flatAmount: formatCurrency(
          (form.getValues("amount") * (taxRate / 100)).toFixed(2),
        ),
      };
      paymentAdjustments.push(tax);
    }
    if (paymentAdjustment !== ADJUSTMENT_TYPES.NONE) {
      const processorAmount = form.getValues("paymentAdjustmentValue");
      const processorAdjustment = {
        type:
          paymentAdjustment === ADJUSTMENT_TYPES.PERCENTAGE
            ? "ServiceFee"
            : "ConvenienceFee",
        ...(paymentAdjustment === ADJUSTMENT_TYPES.FLAT && {
          flatAmount: (processorAmount / 100).toFixed(2),
        }),
        ...(paymentAdjustment === ADJUSTMENT_TYPES.PERCENTAGE && {
          percentage: (processorAmount / 1000).toFixed(3),
        }),
      };
      paymentAdjustments.push(processorAdjustment);
    }
    return paymentAdjustments;
  };

  const submitTransaction = (payload) => {
    setSpinnerOpen(true);
    let url = stringFormat(sassEndpoints.transactions.transaction, [
      userSettings.gatewayId,
    ]);
    axios
      .post(url, payload)
      .then(function (response) {
        if (
          response?.data?.data?.status?.toLowerCase() ===
          TRANSACTION_RESPONSES.DECLINED
        ) {
          setErrorMessage(
            response?.data?.data?.processorResponseCode +
              " - " +
              response?.data?.data?.processorResponseText,
          );
        } else {
          const total = calculateTotal(
            form.getValues().amount,
            taxRules.taxToTotal ? taxRate : 0,
            paymentAdjustment,
            form.getValues()?.paymentAdjustmentValue,
          );
          if (response?.status === 207) {
            setTransactionState({
              isSuccess: true,
              transactionData: { statusCode: 207, total: total },
            });
          } else {
            response.data.data.total = total;
            setTransactionState({
              isSuccess: true,
              transactionData: response?.data?.data,
            });
          }
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.statusDetails);
      })
      .finally(() => {
        setSpinnerOpen(false);
        if (achToken) {
          setAchToken(null);
        }
      });
  };

  const formatCurrency = (amount) => {
    return Number((amount / 100).toFixed(2));
  };

  /* eslint-disable no-unused-vars */
  const achTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig.gatewayConfigSettings,
      "required_fields",
      "Default",
      "payment_method_ach",
    ) !== "hidden" && tokenizerConfiguration.achTokenizer !== iqProVer.v2
      ? useTokenizer({
          paymentType: "ach",
          apikey: userSettings.publicKey,
          onSubmit: handleTokenizerSubmission,
          container: "#tokenizer-ach",
          onAchChange: handleSECChange,
          showCvv: false,
          availableSEC: availableSECCodes,
        })
      : null;
  return (
    <div>
      <div className="sub-wrapper ach-payment">
        {transactionState.isSuccess ? (
          <PaymentSuccess
            transactionData={transactionState.transactionData}
            returnToTransaction={onSuccess}
          />
        ) : (
          <>
            <PaymentDetails
              processors={processors}
              defaultProcessor={defaultProcessor}
              paymentAdjustment={paymentAdjustment}
              setPaymentAdjustment={setPaymentAdjustment}
              handleSECChange={handleSECChange}
              billingAddress={billingAddress}
              achSubmit={achSubmit}
              setAchSubmit={setAchSubmit}
              achToken={achToken}
              setAchToken={setAchToken}
              manualPayment={manualPayment}
              setManualPayment={setManualPayment}
            />
            <PaymentReview paymentAdjustment={paymentAdjustment} />
            <button
              onClick={submitPayment}
              style={{ marginBottom: "12px" }}
              className="btn btn--primary"
            >
              Submit
            </button>
            <button onClick={handleClose} className="btn btn--secondary">
              Cancel
            </button>
            {errorMessage && (
              <div
                style={{ marginTop: "16px", width: "100%" }}
                className={`tag tag__failed`}
              >
                <p>{errorMessage}</p>
              </div>
            )}
          </>
        )}
      </div>
      <Backdrop
        open={spinnerOpen}
        transitionDuration={0}
        sx={{ color: "#fff", zIndex: 9000, opacity: 0.5 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

ACHPaymentContainer.propTypes = {
  processors: PropTypes.array,
  onSuccess: PropTypes.func,
  handleClose: PropTypes.func,
};
export default ACHPaymentContainer;
