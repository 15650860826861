import React, { useEffect, useState, useContext } from "react";
import PaymentDetails from "./PaymentDetails";
import PaymentMethodSelector from "./PaymentMethodSelector";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import "../../../assets/v2/main.scss";
import "./terminal.scss";
import { TerminalContext } from "./TerminalContext";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { noProcessors } from "../../../constants/routes";
import { useHistory } from "react-router";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { GatewayConfigContext } from "../../../contexts/GatewayConfigContext";
import { merchantRoutes } from "../../../constants/routes";
import Switch from "../../ReactHookForm/Switch";
const TerminalContainer = () => {
  const methods = useForm();
  const [paymentType, setPaymentType] = useState("sale");
  const [customer, setCustomer] = useState(null);
  const [resetCustomFields, setResetCustom] = useState(0);
  const gatewayInfo = useContext(GatewayContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const history = useHistory();

  const defaultTaxToTotal =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Virtual Terminal",
      "add_tax_to_total",
    ) || null;

  const [taxRules, setTaxRules] = useState({
    taxExempt: false,
    taxToTotal: defaultTaxToTotal.toLowerCase() === "true",
  });

  const surchargeEnabled =
    gatewayInfo.gatewaySettings
      .find((s) => s.code === "ALLOW_SURCHARGE")
      ?.value?.toLowerCase() === "true";

  useEffect(() => {
    if (gatewayInfo?.processors?.length === 0) {
      history.push(noProcessors);
    }
    if (
      !gatewayInfo?.processors.some((p) => {
        return p.processorType.certificationCategory === "PhysicalTerminal";
      }) &&
      surchargeEnabled
    ) {
      history.push({
        pathname: `${merchantRoutes.virtual_terminal}`,
      });
    }
  }, []);

  const resetMainState = () => {
    //clean up form state todo
    setTaxRules({
      taxExempt: false,
      taxToTotal: defaultTaxToTotal.toLowerCase() === "true",
    });
    setResetCustom((prevCount) => prevCount + 1);
    methods.reset({ amount: 0, description: "", poNumber: "", orderId: "" });
    setPaymentType("sale");
    setCustomer(null);
  };

  const toggleVirtualTerminal = () => {
    if (localStorage.getItem("VT2")) {
      localStorage.removeItem("VT2");
    }
    history.push({
      pathname: `${merchantRoutes.virtual_terminal}`,
    });
  };

  return (
    <TerminalContext.Provider
      value={{
        paymentType,
        setPaymentType,
        taxRules,
        setTaxRules,
        customer,
        setCustomer,
        resetCustomFields,
      }}
    >
      <div className="v2 terminal-wrapper">
        <div className="iqPro-page-container">
          <div className="sub-wrapper">
            <FormProvider {...methods}>
              <div className="terminal-header">
                <h1>Terminal</h1>
                {localStorage.getItem("VT2") && (
                  <Switch
                    control={methods.control}
                    label={"New Terminal"}
                    labelPlacement="start"
                    name={"terminal-preview"}
                    defaultValue={true}
                    onChange={() => {
                      toggleVirtualTerminal();
                    }}
                  />
                )}
              </div>

              <PaymentDetails />
              <PaymentMethodSelector resetState={resetMainState} />
            </FormProvider>
          </div>
        </div>
      </div>
    </TerminalContext.Provider>
  );
};

export default TerminalContainer;
