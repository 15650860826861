import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import BasicCard from "../../Cards/BasicCard";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import Select from "../../ReactHookForm/Select";
import { validationRules } from "../../../constants/validationRules";
import { PAYMENT_TYPE } from "./PaymentTabs";
import { regexPatterns } from "../../../constants/regexPatterns";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";

const selectableProcessors = (processor, paymentMethod) => {
  return (
    processor?.supportedPaymentMethods?.includes(paymentMethod) &&
    !processor?.features?.hideInVirtualTerminal &&
    Object.keys(processor).includes("settings")
  );
};

const getProcessorDetails = (processors, processorId) => {
  if (processors?.length > 0) {
    return processors?.find(
      (processor) => processor.processorId === processorId,
    );
  }
  return {};
};

const getDefaultProcessorDetails = (processors, paymentOption) => {
  const defaultProcessor =
    processors?.length > 0
      ? processors?.find((processor) =>
          paymentOption === PAYMENT_TYPE.CARD
            ? processor.isDefaultCard
            : processor.isDefaultAch,
        )
      : null;
  return defaultProcessor ? defaultProcessor : {};
};

const PaymentDetails = ({
  paymentOption,
  gatewayConfig,
  gatewayInfo,
  processors,
  setSelectedProcessorId,
  vaultRecordHidden,
  setVaultCustomer,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const [paymentAdjustmentType, setPaymentAdjustmentType] = useState("NoAdj");
  const [processorOptions, setProcessorOptions] = useState([]);
  const [isEmailSelected, setEmailSelected] = useState(false);
  const processorValueField = gatewayConfig.categories
    .find((c) => c.displayName === "Required Fields")
    ?.groups[0].fields.find((obj) => obj.label === "Processor Id")?.value;
  const showProcessor =
    typeof processorValueField === "object"
      ? processorValueField[0] !== "hidden"
        ? true
        : false
      : processorValueField !== "hidden"
        ? true
        : false;

  const paymentAdjustmentSetting = getGatewayConfigFieldVal(
    gatewayConfig,
    "required_fields",
    "Default",
    "payment_adjustment",
  );

  const taxAmountSetting = getGatewayConfigFieldVal(
    gatewayConfig,
    "required_fields",
    "Default",
    "tax_amount",
  );

  const taxLocked = useWatch({
    control,
    name: "tax_locked",
    defaultValue: true,
  });

  const taxExempt = useWatch({
    control,
    name: "tax_exempt",
    defaultValue: false,
  });

  useEffect(() => {
    if (processors?.length > 0) {
      let processorOptions = processors;
      if (showProcessor) {
        processorOptions = processors?.filter((processor) => {
          return selectableProcessors(processor, paymentOption);
        });
        setProcessorOptions(processorOptions);
      }

      const processorDetails = getDefaultProcessorDetails(
        processorOptions,
        paymentOption,
      );
      setValue("processor_id", processorDetails?.processorId || "");
      setSelectedProcessorId(processorDetails?.processorId || "");
      setPaymentAdjustmentFormValues(
        processorDetails?.paymentAdjustments ?? [],
      );
    }
  }, [processors, paymentOption]);

  const setPaymentAdjustmentFormValues = (paymentAdjustments = []) => {
    var type = "NoAdj";
    if (
      paymentAdjustments.length === 1 &&
      paymentAdjustments[0].type.toLowerCase() === "servicefee"
    ) {
      type = "Percentage";
      setValue("payment_adjustment.type", type);
      setValue("payment_adjustment", {
        type: type,
        value: paymentAdjustments[0].percentage * 1000,
        add_to_amount: false,
      });
      setPaymentAdjustmentType(type);
    } else if (
      paymentAdjustments.length === 1 &&
      paymentAdjustments[0].type.toLowerCase() === "conveniencefee"
    ) {
      type = "Flat";
      setValue("payment_adjustment.type", type);
      setValue("payment_adjustment", {
        type: type,
        value: paymentAdjustments[0].flatAmount * 100,
        add_to_amount: false,
      });
      setPaymentAdjustmentType(type);
    } else {
      setValue("payment_adjustment.type", type);
      setValue("payment_adjustment", {
        type: type,
        value: 0,
        add_to_amount: false,
      });
      setPaymentAdjustmentType(type);
    }
  };

  const handleProcessorChange = (id) => {
    setSelectedProcessorId(id);
    const processorDetails = getProcessorDetails(processorOptions, id);
    setPaymentAdjustmentFormValues(processorDetails?.paymentAdjustments ?? []);
  };

  const handlePaymentAdjustmentChange = (type = "NoAdj") => {
    switch (type) {
      case "NoAdj":
        setPaymentAdjustmentFormValues([]);
        break;
      case "Percentage":
        setPaymentAdjustmentFormValues([
          { type: "ServiceFee", percentage: 0, flatAmount: null },
        ]);
        break;
      case "Flat":
        setPaymentAdjustmentFormValues([
          { type: "ConvenienceFee", percentage: null, flatAmount: 0 },
        ]);
        break;
    }
  };

  const handleTaxExemptChange = (checked) => {
    if (checked) {
      setValue("add_tax_to_total", false);
      setValue("tax_locked", true);
    } else if (
      getGatewayConfigFieldVal(
        gatewayConfig,
        "required_fields",
        "Default",
        "tax_amount",
      ) === "hidden"
    ) {
      setValue(
        "add_tax_to_total",
        getGatewayConfigFieldVal(
          gatewayConfig,
          "default_values",
          "Virtual Terminal",
          "add_tax_to_total",
        ).toLowerCase() === "true",
      );
    }
  };

  const handleReceipt = (e) => {
    const isEmail = e.toLowerCase() === "true";
    setEmailSelected(isEmail);
    if (isEmail) {
      const billingEmail = getValues("billing_address").email;
      if (String(billingEmail).toLowerCase().match(regexPatterns.email)) {
        setValue("email_address", billingEmail);
      } else {
        setValue("email_address", "");
      }
    }
  };

  return (
    <BasicCard title="Details">
      <Grid container spacing={2}>
        {showProcessor && (
          <Grid item xs={12}>
            <Select
              control={control}
              name="processor_id"
              label="Processor"
              fullWidth
              rules={{ required: "Please select processor" }}
              errors={errors}
              onChange={handleProcessorChange}
              data-cy="vt-processor-select"
            >
              {processorOptions.map((processor) => (
                <MenuItem
                  key={`option-value-${processor.processorId}`}
                  value={processor.processorId}
                >
                  {processor.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        {paymentAdjustmentSetting !== "hidden" &&
          gatewayInfo.gatewaySettings
            .find((s) => s.code === "ALLOW_SURCHARGE")
            ?.value.toLowerCase() !== "true" && (
            <>
              <Grid
                item
                xs={12}
                sm={paymentAdjustmentType === "NoAdj" ? 12 : 6}
              >
                <Select
                  control={control}
                  name="payment_adjustment.type"
                  label="Payment Adjustment"
                  fullWidth
                  defaultValue="NoAdj"
                  rules={{
                    required: paymentAdjustmentSetting === "required",
                    validate:
                      paymentAdjustmentSetting === "required"
                        ? (type) => type !== "NoAdj"
                        : null,
                  }}
                  errors={errors}
                  onChange={handlePaymentAdjustmentChange}
                  data-cy="vt-payment-adjustment"
                >
                  <MenuItem value={"NoAdj"}>No Adjustment</MenuItem>
                  <MenuItem value={"Flat"}>Flat</MenuItem>
                  <MenuItem value={"Percentage"}>Percentage</MenuItem>
                </Select>
              </Grid>
              {paymentAdjustmentType !== "NoAdj" && (
                <Grid item xs={12} sm={6}>
                  <FormattedTextField
                    type={
                      paymentAdjustmentType === "Flat"
                        ? "currency"
                        : paymentAdjustmentType
                    }
                    name="payment_adjustment.value"
                  />
                </Grid>
              )}
            </>
          )}
        {taxAmountSetting !== "hidden" && (
          <Grid item xs={12}>
            <FormControl component="fieldset" variant="standard" fullWidth>
              <FormLabel
                component="legend"
                required={taxAmountSetting === "required"}
              >
                Tax Amount
              </FormLabel>
              <FormGroup row sx={{ display: "flex", flexWrap: "nowrap" }}>
                <IconButton
                  aria-label="lock"
                  size="large"
                  sx={{
                    border: "1px solid",
                    borderRadius: 1,
                    borderColor: "silver",
                    mr: 0.5,
                    fontSize: 20,
                    height: 32,
                  }}
                  onClick={() => {
                    if (!taxExempt) setValue("tax_locked", !taxLocked);
                  }}
                >
                  {taxLocked ? (
                    <LockOutlinedIcon fontSize="inherit" />
                  ) : (
                    <LockOpenOutlinedIcon fontSize="inherit" />
                  )}
                </IconButton>
                <FormattedTextField
                  disabled={taxLocked}
                  name="tax_amount"
                  type="currency"
                  defaultValue={0}
                  rules={{
                    required: taxAmountSetting === "required",
                    validate: () => {
                      if (
                        Number(getValues("tax_amount")) >
                        Number(getValues("amount"))
                      )
                        return "Amount must be greater than tax";
                    },
                  }}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        {(getGatewayConfigFieldVal(
          gatewayConfig,
          "required_fields",
          "Default",
          "tax_exempt",
        ) !== "hidden" ||
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "tax_amount",
          ) !== "hidden") && (
          <Grid item xs={12}>
            <FormGroup aria-label="tax-switches" row>
              {getGatewayConfigFieldVal(
                gatewayConfig,
                "required_fields",
                "Default",
                "tax_amount",
              ) !== "hidden" && (
                <FormControl component="fieldset">
                  <FormLabel sx={{ mr: 2 }}>Add Tax To Total</FormLabel>
                  <Controller
                    render={({ field }) => (
                      <Switch
                        color="primary"
                        checked={
                          typeof field.value === "object"
                            ? field.value[0]
                            : field.value
                        }
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                    name="add_tax_to_total"
                    control={control}
                    defaultValue={
                      getGatewayConfigFieldVal(
                        gatewayConfig,
                        "default_values",
                        "Virtual Terminal",
                        "add_tax_to_total",
                      ).toLowerCase() === "true"
                    }
                  />
                </FormControl>
              )}
              {getGatewayConfigFieldVal(
                gatewayConfig,
                "required_fields",
                "Default",
                "tax_exempt",
              ) !== "hidden" &&
                getGatewayConfigFieldVal(
                  gatewayConfig,
                  "required_fields",
                  "Default",
                  "tax_amount",
                ) !== "hidden" && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ mr: 2, backgroundColor: "whitesmoke" }}
                  />
                )}
              {getGatewayConfigFieldVal(
                gatewayConfig,
                "required_fields",
                "Default",
                "tax_exempt",
              ) !== "hidden" && (
                <FormControl component="fieldset">
                  <FormLabel>Tax Exempt</FormLabel>
                  <Controller
                    render={({ field }) => (
                      <Switch
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e);
                          handleTaxExemptChange(e.target.checked);
                        }}
                      />
                    )}
                    name="tax_exempt"
                    control={control}
                    defaultValue={false}
                  />
                </FormControl>
              )}
            </FormGroup>
          </Grid>
        )}
        {getGatewayConfigFieldVal(
          gatewayConfig,
          "required_fields",
          "Default",
          "description",
        ) !== "hidden" && (
          <Grid item xs={12}>
            <RegisteredTextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "100px",
                },
              }}
              name="description"
              label="Description"
              id="outlined-multiline-static"
              fullWidth
              multiline
              rows={4}
              rules={{
                required:
                  getGatewayConfigFieldVal(
                    gatewayConfig,
                    "required_fields",
                    "Default",
                    "description",
                  ) === "required",
                maxLength: validationRules.maxLength255,
              }}
              inputProps={{
                "data-cy": "description",
              }}
            />
          </Grid>
        )}
        {getGatewayConfigFieldVal(
          gatewayConfig,
          "required_fields",
          "Default",
          "order_id",
        ) !== "hidden" && (
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="order"
              name="order_id"
              label="Order Id"
              fullWidth
              rules={{
                required:
                  getGatewayConfigFieldVal(
                    gatewayConfig,
                    "required_fields",
                    "Default",
                    "order_id",
                  ) === "required",
                maxLength: validationRules.maxLength15,
                pattern: regexPatterns.alphanumericSpace,
              }}
              inputProps={{
                "data-cy": "order",
              }}
            />
          </Grid>
        )}
        {getGatewayConfigFieldVal(
          gatewayConfig,
          "required_fields",
          "Default",
          "po_number",
        ) !== "hidden" && (
          <Grid item xs={12} sm={6}>
            <RegisteredTextField
              id="poNumber"
              name="po_number"
              label="PO Number"
              fullWidth
              rules={{
                required:
                  getGatewayConfigFieldVal(
                    gatewayConfig,
                    "required_fields",
                    "Default",
                    "po_number",
                  ) === "required",
                maxLength: validationRules.maxLength15,
                pattern: regexPatterns.alphanumericSpace,
              }}
              inputProps={{
                "data-cy": "poNumber",
              }}
            />
          </Grid>
        )}

        {getGatewayConfigFieldVal(
          gatewayConfig,
          "required_fields",
          "Default",
          "email_receipt",
        ) !== "hidden" && (
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel>Email Receipt</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup row aria-label="email-receipt" {...field}>
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Yes"
                      data-cy="email-receipt-yes"
                      onChange={(e) => {
                        handleReceipt(e.target.value);
                      }}
                    />
                    <FormControlLabel
                      id="TESTCY"
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                      data-cy="email-receipt-no"
                      onChange={(e) => {
                        handleReceipt(e.target.value);
                      }}
                    />
                  </RadioGroup>
                )}
                name="email_receipt"
                control={control}
                defaultValue={false}
              />
            </FormControl>
            {isEmailSelected && (
              <RegisteredTextField
                id="email_address"
                name="email_address"
                label="Email"
                fullWidth
                rules={{
                  pattern: validationRules.email,
                  maxLength: validationRules.maxLength100,
                  required: true,
                }}
              />
            )}
          </Grid>
        )}

        {vaultRecordHidden ? (
          <></>
        ) : (
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel>Vault Record</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup row aria-label="vault-record" {...field}>
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Yes"
                      data-cy="vault-record-yes"
                      onChange={() => setVaultCustomer(true)}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                      data-cy="vault-record-no"
                      onChange={() => setVaultCustomer(false)}
                    />
                  </RadioGroup>
                )}
                name="create_vault_record"
                control={control}
                defaultValue={false}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </BasicCard>
  );
};

PaymentDetails.propTypes = {
  paymentOption: PropTypes.string.isRequired,
  gatewayConfig: PropTypes.object.isRequired,
  gatewayInfo: PropTypes.object.isRequired,
  processors: PropTypes.array.isRequired,
  setSelectedProcessorId: PropTypes.func,
  vaultRecordHidden: PropTypes.bool,
  setVaultCustomer: PropTypes.func,
};

export default PaymentDetails;
