import React, { useContext, useState } from "react";
import { merchantRoutes } from "../../../../constants/routes";
import PropTypes from "prop-types";
import axios from "axios";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import success from "../PhysicalTerminal/assets/success-pt.png";
import successCard from "../../../../assets/images/Transaction/SuccessfulTransaction.png";
import { useHistory } from "react-router";
import { formatCurrency } from "../../../../utils/stringHelpers";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import SendIcon from "@mui/icons-material/Send";
import { useFormContext, useWatch } from "react-hook-form";
import { GatewayContext } from "../../../../contexts/GatewayContext";
const PaymentSuccess = ({ transactionData, returnToTransaction }) => {
  const gatewayInfo = useContext(GatewayContext);
  const [loading, setLoading] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const { userSettings } = useContext(UserSettingsContext);
  const history = useHistory();
  const form = useFormContext();
  const email = useWatch({ name: "emailReceipt" });
  const handleNavigation = () => {
    history.push(
      `${merchantRoutes.reporting.transaction_detail}/${transactionData?.transactionId}`,
    );
  };
  const printTransaction = async () => {
    let outErrorMsg = null;
    setLoading(true);
    const url = stringFormat(sassEndpoints.transactions.receipt, [
      userSettings.gatewayId,
      transactionData?.transactionId,
    ]);

    await axios
      .get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url, "receipt-" + transactionData?.transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
        return false;
      })
      .finally(function () {
        setLoading(false);
      });

    return outErrorMsg;
  };

  const emailReceipt = () => {
    const email = form.getValues("emailReceipt");
    const url = stringFormat(sassEndpoints.transactions.emailReceipt, [
      userSettings.gatewayId,
      transactionData?.transactionId,
    ]);
    setLoading(true);
    axios
      .post(url, {
        recipient: {
          to: [email],
          name: "",
        },
        from: gatewayInfo.receiptEmail || "",
      })
      .then(() => {
        setDisableEmail(true);
      })
      .catch(function () {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="payment-success-modal">
      <div>
        <p className="h2 transaction-amount">
          {formatCurrency(transactionData?.total)}
        </p>
        <img src={transactionData.statusCode === 207 ? successCard : success} />
        <h2>Transaction Complete</h2>
        {transactionData.statusCode === 207 ? (
          <>
            <div
              style={{ marginTop: "24px", width: "100%", maxWidth: "450px" }}
              className={`tag tag__warning important`}
            >
              <p>
                The transaction was processed successfully, but we are currently
                experiencing a minor issue displaying your record. If the record
                is not available within 24 hours, please contact support.
              </p>
            </div>
            <button
              disabled={loading}
              onClick={returnToTransaction}
              className="btn btn--primary"
            >
              Okay
            </button>
          </>
        ) : (
          <>
            <button
              disabled={loading}
              onClick={handleNavigation}
              className="btn btn--primary"
            >
              View Transaction Details
            </button>
            <button
              onClick={printTransaction}
              disabled={loading}
              className="btn btn--secondary"
            >
              Print Receipt
            </button>
            <button
              onClick={returnToTransaction}
              disabled={loading}
              className="btn btn--secondary"
            >
              New Transaction
            </button>

            {!disableEmail ? (
              <div className="email-receipt-success">
                <RegisteredTextField
                  name={"emailReceipt"}
                  label="Email"
                  fullWidth
                  placeholder="receipt@email.com"
                  rules={{
                    pattern: validationRules.email,
                    maxLength: validationRules.maxLength100,
                  }}
                  disabled={loading}
                  v2={true}
                />

                <button
                  onClick={emailReceipt}
                  disabled={
                    loading || !email?.match(validationRules.email.value)
                  }
                  className="btn btn--primary sm"
                >
                  Email Receipt
                  <SendIcon />
                </button>
              </div>
            ) : (
              <div
                style={{ marginTop: "16px", width: "100%" }}
                className={`tag tag__success`}
              >
                <p>Transaction receipt sent</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
PaymentSuccess.propTypes = {
  transactionData: PropTypes.object,
  returnToTransaction: PropTypes.func,
};

export default PaymentSuccess;
