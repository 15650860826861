import React, { useContext, useState, useEffect } from "react";
import "../../../../assets/v2/main.scss";
import "../PhysicalTerminal/physicalTerminal.scss";
import {
  defaultHiddenFields,
  clearedContactAddress,
  setAddress,
} from "../terminalHelpers";
import { TerminalContext } from "../TerminalContext";
import ContactContainer from "../../../Contact/ContactContainer";
import Select from "../../../ReactHookForm/Select";
import MenuItem from "@mui/material/MenuItem";
import { CountriesContext } from "../../../../contexts/CountriesContext";
import { useFormContext } from "react-hook-form";
const CardAddress = () => {
  const [showBilling, setShowBilling] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [defaultBillingAddress, setBillingAddress] = useState(
    clearedContactAddress,
  );
  const [defaultShippingAddress, setShippingAddress] = useState(
    clearedContactAddress,
  );
  const { customer } = useContext(TerminalContext);
  const countries = useContext(CountriesContext);
  const form = useFormContext();

  useEffect(() => {
    if (customer?.addresses?.length > 0) {
      const defaultBilling = customer?.addresses.find((a) => {
        return a.isBilling;
      });
      const defaultShipping = customer?.addresses.find((a) => {
        return a.isShipping;
      });
      if (defaultBilling) {
        setBillingAddress(
          setAddress(defaultBilling.customerAddressId, customer),
        );
      }
      if (defaultShipping) {
        setShippingAddress(
          setAddress(defaultShipping.customerAddressId, customer),
        );
      }
    }
  }, []);
  return (
    <div className="address-wrapper" style={{ marginBottom: "16px" }}>
      <button
        className="btn--link"
        onClick={() => {
          setShowBilling(!showBilling);
        }}
      >
        <span>Billing Address </span> {showBilling ? "-" : "+"}
      </button>
      <div
        style={{ marginBottom: "12px" }}
        className={!showBilling ? "hide-address" : undefined}
      >
        {customer?.addresses.length > 1 && (
          <Select
            name={`billingDropdown`}
            control={form.control}
            label="Addresses"
            defaultValue={
              customer?.addresses.find((a) => {
                return a.isBilling;
              })?.customerAddressId
            }
            errors={form.formState.errors}
            v2={true}
            sx={{ marginBottom: "16px", width: "50% !important" }}
            onChange={(id) => {
              setBillingAddress(setAddress(id, customer));
            }}
          >
            {customer?.addresses.map((a, i) => {
              return (
                <MenuItem key={i} value={a.customerAddressId}>
                  {a.addressLine1}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <ContactContainer
          type="billing"
          title="Address"
          countries={countries}
          country="US"
          hidden={defaultHiddenFields}
          v2={true}
          defaultValues={defaultBillingAddress}
          // required={getRequiredFields()}
        />
      </div>
      <button
        className="btn--link"
        onClick={() => {
          setShowShipping(!showShipping);
        }}
      >
        <span>Shipping Address </span>
        {showShipping ? "-" : "+"}
      </button>
      <div className={!showShipping ? "hide-address" : undefined}>
        {customer?.addresses.length > 1 && (
          <Select
            name={`shippingDropdown`}
            control={form.control}
            label="Addresses"
            defaultValue={
              customer?.addresses.find((a) => {
                return a.isShipping;
              })?.customerAddressId || null
            }
            onChange={(id) => {
              setShippingAddress(setAddress(id, customer));
            }}
            errors={form.formState.errors}
            v2={true}
            sx={{ marginBottom: "16px", width: "50% !important" }}
          >
            {customer?.addresses.map((a, i) => {
              return (
                <MenuItem key={i} value={a.customerAddressId}>
                  {a.addressLine1}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <ContactContainer
          type="shipping"
          title="Address"
          countries={countries}
          country="US"
          hidden={defaultHiddenFields}
          defaultValues={defaultShippingAddress}
          v2={true}
          // required={getRequiredFields()}
        />
      </div>
    </div>
  );
};

export default CardAddress;
