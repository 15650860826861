import React, { useContext, useState } from "react";
import { merchantRoutes } from "../../../../constants/routes";
import PropTypes from "prop-types";
import axios from "axios";
import { sassEndpoints } from "../../../../constants/endpoints";
import { stringFormat } from "../../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../../contexts/UserSettingsContext";
import success from "./assets/success-pt.png";
import { useHistory } from "react-router";
import RegisteredTextField from "../../../ReactHookForm/RegisteredTextField";
import { validationRules } from "../../../../constants/validationRules";
import SendIcon from "@mui/icons-material/Send";
import { useFormContext, useWatch } from "react-hook-form";
import { GatewayContext } from "../../../../contexts/GatewayContext";
import { formatCurrency } from "../../../../utils/stringHelpers";
const TerminalSuccess = ({
  transactionId,
  returnToTransaction,
  totalAmount,
}) => {
  const gatewayInfo = useContext(GatewayContext);
  const [loading, setLoading] = useState(false);
  const { userSettings } = useContext(UserSettingsContext);
  const history = useHistory();
  const [disableEmail, setDisableEmail] = useState(false);
  const form = useFormContext();
  const email = useWatch({ name: "emailReceipt" });

  const handleNavigation = () => {
    history.push(
      `${merchantRoutes.reporting.transaction_detail}/${transactionId}`,
    );
  };
  const printTransaction = async () => {
    let outErrorMsg = null;
    setLoading(true);
    const url = stringFormat(sassEndpoints.transactions.receipt, [
      userSettings.gatewayId,
      transactionId,
    ]);

    await axios
      .get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        window.open(url, "receipt-" + transactionId);
      })
      .catch(function (error) {
        outErrorMsg = error.response.data.statusDetails.join(", ");
        return false;
      })
      .finally(function () {
        setLoading(false);
      });

    return outErrorMsg;
  };

  const emailReceipt = () => {
    const email = form.getValues("emailReceipt");
    const url = stringFormat(sassEndpoints.transactions.emailReceipt, [
      userSettings.gatewayId,
      transactionId,
    ]);
    setLoading(true);
    axios
      .post(url, {
        recipient: {
          to: [email],
          name: "",
        },
        from: gatewayInfo.receiptEmail || "",
      })
      .then(() => {
        setDisableEmail(true);
      })
      .catch(function () {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="payment-success-modal pt-success-wrapper">
      <div>
        <p className="h2 transaction-amount">{formatCurrency(totalAmount)}</p>
        <img src={success} />
        <h2>Transaction Complete</h2>
        <button
          disabled={loading}
          onClick={handleNavigation}
          className="btn btn--primary"
        >
          View Transaction Details
        </button>
        <button
          onClick={printTransaction}
          disabled={loading}
          className="btn btn--secondary"
        >
          Print Receipt
        </button>
        <button
          onClick={returnToTransaction}
          disabled={loading}
          className="btn btn--secondary"
        >
          New Transaction
        </button>
        {!disableEmail ? (
          <div className="email-receipt-success">
            <RegisteredTextField
              name={"emailReceipt"}
              label="Email"
              fullWidth
              placeholder="receipt@email.com"
              rules={{
                pattern: validationRules.email,
                maxLength: validationRules.maxLength100,
              }}
              disabled={loading}
              v2={true}
            />

            <button
              onClick={emailReceipt}
              disabled={loading || !email?.match(validationRules.email.value)}
              className="btn btn--primary sm"
            >
              Email Receipt
              <SendIcon />
            </button>
          </div>
        ) : (
          <div
            style={{ marginTop: "16px", width: "100%" }}
            className={`tag tag__success`}
          >
            <p>Transaction receipt sent</p>
          </div>
        )}
      </div>
    </div>
  );
};
TerminalSuccess.propTypes = {
  transactionId: PropTypes.string,
  returnToTransaction: PropTypes.func,
  totalAmount: PropTypes.number,
};

export default TerminalSuccess;
