import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import StyledTabs from "../../Tabs/StyledTabs";
import PropTypes from "prop-types";
import { useTokenizer } from "../../../hooks/useTokenizer";
import { PAYMENT_TYPE } from "../VirtualTerminal/PaymentTabs";
import ContactContainer from "../../Contact/ContactContainer";
import CVCreateAdvancedOptions from "./CVCreateAdvancedOptions";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import BasicCard from "../../Cards/BasicCard";
import { useFormContext } from "react-hook-form";
import { TokenizerConfigContext } from "../../../contexts/TokenizerConfigContext";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { iqProVer } from "../../../constants/global";
import { GatewayContext } from "../../../contexts/GatewayContext";

function CVCreate({
  gatewayConfig,
  gatewayInfo,
  tokenizerApiKey,
  formOutput,
  clickEvent,
  handleFormTouched,
  countries,
  paymentPanels,
  handleTabChange,
  submitNewCustomer,
  onError,
  methods,
  goBack,
  customFieldGroups,
  chosenCustomFields,
  secCode,
  handleAchChange,
  setAchSubmit,
  handleTokenizerSubmission,
}) {
  const { control } = useFormContext();
  useImperativeHandle(clickEvent, () => ({
    createCustomer: () => {
      if (activeTab === "card") {
        if (tokenizerSettings.achTokenizer === iqProVer.v1) {
          cardTokenizer.submit();
        }
      } else if (activeTab === "ach") {
        if (tokenizerSettings.achTokenizer === iqProVer.v1) {
          achTokenizer.submit();
        }
        if (tokenizerSettings.achTokenizer === iqProVer.v2) {
          setAchSubmit(true);
        }
      } else {
        formOutput(null);
      }
    },
  }));

  const [activeTab, setActiveTab] = useState();

  const [contactRequirements, setRequirements] = useState();

  const [dirtyBillingForm, setDirtyBillingForm] = useState(false);
  const [changedBillingField, setChangedBillingField] = useState();
  const [changedShippingField, setChangedShippingField] = useState();

  const [dirtyShippingForm, setDirtyShippingForm] = useState(false);
  const tokenizerSettings = useContext(TokenizerConfigContext);
  const gatewayContext = useContext(GatewayContext);

  const handleChange = (tabValue) => {
    setActiveTab(paymentPanels[tabValue].value);
    handleRequirements(paymentPanels[tabValue].value);
  };

  useEffect(() => {
    setActiveTab(paymentPanels[0].value);
    handleRequirements(paymentPanels[0].value);
  }, []);

  useEffect(() => {
    if (methods.getValues().billing_address) {
      let isDirty = false;
      Object.entries(methods.getValues().billing_address).forEach(
        (formValue) => {
          if (
            formValue[0] !== "country" &&
            formValue[1] &&
            control.getFieldState("billing_address").isDirty
          ) {
            isDirty = true;
          }
        },
      );
      setDirtyBillingForm(isDirty);
      handleFormTouched("billing", isDirty);
    }
  }, [changedBillingField]);

  useEffect(() => {
    if (methods.getValues().shipping_address) {
      let isDirty = false;
      Object.entries(methods.getValues().shipping_address).forEach(
        (formValue) => {
          if (
            formValue[0] !== "country" &&
            formValue[1] &&
            control.getFieldState("shipping_address").isDirty
          ) {
            isDirty = true;
          }
        },
      );
      setDirtyShippingForm(isDirty);
      handleFormTouched("shipping", isDirty);
    }
  }, [changedShippingField]);

  useEffect(() => {
    handleRequirements(activeTab);
  }, [dirtyBillingForm, dirtyShippingForm]);

  useEffect(() => {
    handleTabChange(activeTab);
  }, [activeTab]);

  useEffect(() => {
    handleRequirements(activeTab);
  }, [secCode]);

  const handleRequirements = (activeTab) => {
    if (activeTab === "ach") {
      getRequiredSECFields(secCode);
    } else {
      const requirements = {
        billing: createRequiredFields(true),
        shipping: createRequiredFields(false),
      };
      getDirtyAddressRequiredFields(requirements);
      setRequirements(requirements);
    }
  };

  const getRequiredSECFields = (secCode) => {
    let requirements = {
      billing: createRequiredFields(true),
      shipping: createRequiredFields(false),
    };

    let requiredFields = [
      "firstName",
      "lastName",
      "address1",
      "country",
      "state",
      "city",
      "postalCode",
    ];
    if (secCode === "ccd") requiredFields.push("company");
    else if (secCode === "web") requiredFields.push("email");
    else if (secCode === "tel") requiredFields.push("phone");
    requiredFields.forEach((newField) => {
      if (
        !requirements.billing.find((field) => {
          return field === newField;
        })
      ) {
        requirements.billing.push(newField);
      }
    });
    getDirtyAddressRequiredFields(requirements);
    setRequirements(requirements);
  };

  const getDirtyAddressRequiredFields = (requirements) => {
    if (dirtyBillingForm) {
      if (!requirements.billing.includes("firstName"))
        requirements.billing.push("firstName");
      if (!requirements.billing.includes("address1")) {
        requirements.billing.push("address1");
      }
    }
    if (dirtyShippingForm) {
      if (!requirements.shipping.includes("firstName"))
        requirements.shipping.push("firstName");
      if (!requirements.shipping.includes("address1")) {
        requirements.shipping.push("address1");
      }
    }
  };
  const creditCardConfigured = gatewayContext?.processors.some(
    (p) =>
      p.processorType.type.toLowerCase() === "creditcard" &&
      p.processorType.certificationCategory.toLowerCase() === "virtualterminal",
  );
  const cardTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "required_fields",
      "Default",
      "payment_method_card",
    ) !== "hidden" &&
    tokenizerSettings.cardTokenizer !== iqProVer.v2 &&
    creditCardConfigured
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.CARD,
          apikey: tokenizerApiKey,
          onSubmit: handleTokenizerSubmission,
          container: "#tokenizer-card",
          showCvv: false,
        })
      : null;

  const achConfigured = gatewayContext?.processors.some(
    (p) => p.processorType.type.toLowerCase() === "ach",
  );
  const availableSECCodes = [
    ...new Set(
      gatewayContext.processors.flatMap(
        (p) => p?.features?.availableSecCodes ?? [],
      ),
    ),
  ];
  const achTokenizer =
    getGatewayConfigFieldVal(
      gatewayConfig,
      "required_fields",
      "Default",
      "payment_method_ach",
    ) !== "hidden" &&
    tokenizerSettings.achTokenizer !== iqProVer.v2 &&
    achConfigured
      ? useTokenizer({
          paymentType: PAYMENT_TYPE.ACH,
          apikey: tokenizerApiKey,
          onSubmit: handleTokenizerSubmission,
          onAchChange: handleAchChange,
          container: "#tokenizer-ach",
          availableSEC: availableSECCodes,
        })
      : null;

  const createRequiredFields = (isBilling) => {
    if (isBilling) {
      const form = {
        address1:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_address_line_1",
          ) === "required",
        address2:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_address_line_2",
          ) === "required",
        company:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_company",
          ) === "required",
        city:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_city",
          ) === "required",
        email:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_email",
          ) === "required",
        fax:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_fax",
          ) === "required",
        firstName:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_first_name",
          ) === "required",
        lastName:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_last_name",
          ) === "required",
        phone:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_phone",
          ) === "required",
        postalCode:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_postal_code",
          ) === "required",
        state:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_state",
          ) === "required",
        country:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "billing_country",
          ) === "required",
      };
      return parseToArray(form);
    } else {
      const form = {
        address1:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_address_line_1",
          ) === "required",
        address2:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_address_line_2",
          ) === "required",
        company:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_company",
          ) === "required",
        city:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_city",
          ) === "required",
        email:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_email",
          ) === "required",
        fax:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_fax",
          ) === "required",
        firstName:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_first_name",
          ) === "required",
        lastName:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_last_name",
          ) === "required",
        phone:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_phone",
          ) === "required",
        postalCode:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_postal_code",
          ) === "required",
        state:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_state",
          ) === "required",
        country:
          getGatewayConfigFieldVal(
            gatewayConfig,
            "required_fields",
            "Default",
            "shipping_country",
          ) === "required",
      };
      return parseToArray(form);
    }
  };

  const parseToArray = (requiredObject) => {
    let arr = [];
    Object.entries(requiredObject).forEach((key) => {
      if (key[1]) {
        arr.push(key[0]);
      }
    });
    return arr;
  };

  return (
    <div>
      <StyledTabs
        hidePanels={true}
        label="payment"
        defaultTabIndex={0}
        onChange={handleChange}
        tabs={paymentPanels}
      />
      <CVCreateAdvancedOptions
        activeTab={activeTab}
        isSurcharge={
          gatewayInfo.gatewaySettings.find((s) => s.code === "ALLOW_SURCHARGE")
            ?.value === "true"
        }
        customFieldGroups={customFieldGroups}
        chosenCustomFields={chosenCustomFields}
      />
      {contactRequirements && (
        <div className="contact-container">
          <div>
            <BasicCard title="Billing Contact">
              <ContactContainer
                type="billing_address"
                countries={countries}
                country="US"
                required={contactRequirements.billing}
                setTouchedAddressForm={setChangedBillingField}
              />
            </BasicCard>
          </div>
          <div>
            <BasicCard title="Shipping Contact">
              <ContactContainer
                type="shipping_address"
                countries={countries}
                required={contactRequirements.shipping}
                setTouchedAddressForm={setChangedShippingField}
              />
            </BasicCard>
          </div>
        </div>
      )}
      <Grid container>
        <Grid item xs sx={{ mb: 1 }}>
          <Button
            size="small"
            className="create-submit-button"
            variant="outlined"
            color="primary"
            onClick={() => goBack()}
            sx={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={methods.handleSubmit(submitNewCustomer, onError)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

CVCreate.propTypes = {
  gatewayConfig: PropTypes.object,
  gatewayInfo: PropTypes.object,
  tokenizerApiKey: PropTypes.string,
  countries: PropTypes.array,
  formOutput: PropTypes.func,
  clickEvent: PropTypes.object,
  paymentPanels: PropTypes.array,
  invalidToken: PropTypes.func,
  customFields: PropTypes.object,
  handleTabChange: PropTypes.func,
  handleFormTouched: PropTypes.func,
  submitNewCustomer: PropTypes.func,
  onError: PropTypes.func,
  methods: PropTypes.object,
  goBack: PropTypes.func,
  customFieldGroups: PropTypes.array,
  chosenCustomFields: PropTypes.array,
  secCode: PropTypes.string,
  handleAchChange: PropTypes.func,
  setAchSubmit: PropTypes.func,
  handleTokenizerSubmission: PropTypes.func,
};

export default CVCreate;
